@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.events-suggestions {
  width: 100%;
  margin-bottom: rem(15px);

  .flex-data {
    display: flex;
    flex-direction: column;
    border: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;
    width: inherit;

    .flex-item {
      display: flex;
      justify-content: space-between;
      padding: rem(25px 25px 0);
      width: 100%;
      border-bottom: 1px solid $inputBorder;

      &-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        padding-right: 0;
        flex-wrap: nowrap;

        @include mq($until: small-desktop) {
          flex-wrap: wrap;
        }

        &-col {
          width: 100%;
          max-width: rem(920px);
          margin-left: rem(15px);
        }

        &-buttons {
          width: 100%;
          max-width: rem(190px);
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: rem(34px);
          margin-bottom: rem(25px);

          > :nth-of-type(1) {
            margin-right: rem(10px);
          }

          @include mq($until: small-desktop) {
            display: flex;
            max-width: 100%;
            flex-direction: row;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
