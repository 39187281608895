@import 'styles/palette';
@import 'styles/helpers';

.file-upload {
  position: relative;
  padding: rem(20px);
  padding-right: rem(60px);
  border: rem(1px) solid $inputBorder;
  background: #fff;

  .file-upload {
    &-unactive {
      .file-upload-data > span {
        width: 0;
        margin-right: 0;
      }

      .zh-info {
        color: #767676;

        path {
          fill: #767676;
        }
      }
    }
  }

  &-data {
    width: 100%;
    margin-top: rem(5px);
    padding-left: rem(20px);
    display: flex;

    & .zh-progressbar {
      width: rem(150px);
    }

    > span {
      display: block;
      width: rem(150px);
      margin-right: rem(15px);
    }
  }

  &-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }

  .zh-info {
    width: 100%;
  }

  .zh-icon-close {
    position: absolute;
    right: rem(25px);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover path {
      fill: #459c9b;
    }
  }
}
