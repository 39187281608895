@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.institute {
  .zh-form {
    margin-top: rem(2px);
    background: #f8f8f8;
    padding: rem(22px);
    border: 1px solid $inputBorder;

    & h3 {
      @include fontSemiBold;
      color: #414141;
    }

    & h4 {
      font-weight: 500;
    }

    & .zh-form-item {
      max-width: rem(670px);

      & .zh-form-item-label {
        h3 {
          margin: rem(5px 0 12.5px);
        }
      }
    }

    & hr + .zh-form-item {
      margin-top: rem(12.5px);
    }

    & label {
      width: 100%;
    }

    & .zh-form-item-label {
      flex-direction: row-reverse;
      justify-content: flex-end;

      & span {
        color: red;
      }
    }

    &-actions {
      padding: rem(15px);
      border: 1px solid $inputBorder;
      border-top: none;

      & > div:first-child {
        margin-right: rem(15px);
      }
    }
  }

  .flex-data {
    display: flex;
    flex-direction: column;
    border: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;
  }

  .flex-item {
    display: flex;
    justify-content: space-between;
    padding: rem(25px 25px 0);
    border-bottom: 1px solid $inputBorder;

    & svg,
    & path {
      fill: $primary;
    }

    &-map {
      padding: 0;
      border: none;
    }

    label {
      display: flex;
      font-size: rem(13px);
      color: $labelText;
      margin-bottom: rem(10px);
      text-transform: uppercase;
    }

    &-actions {
      white-space: nowrap;

      & > div {
        &:first-child {
          margin-right: rem(15px);
        }
      }
    }

    @include mq($until: desktop) {
      position: relative;

      &-map &-row {
        &-col {
          flex-wrap: wrap;
        }
      }

      &-row {
        &-col {
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;

      &-map &-actions {
        margin-top: 0;
      }
      &-actions {
        margin-top: rem(25px);
        display: flex;
        justify-content: center;
      }

      &-row {
        &-col {
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }
  }
}
