@import 'styles/palette';
@import 'styles/helpers';

.confirm-container {
  display: flex;
  justify-content: center;

  & .zh-loader-inline {
    position: relative;
    display: flex;
    flex-direction: revert;
    flex-wrap: nowrap;
    transform: translate3d(-100%, 0, 0);
    left: 50%;
    width: rem(160px);

    .language-ro & {
      width: rem(180px);
    }
    .language-ru & {
      width: rem(170px);
    }
  }
}

.confirmed-container {
  display: flex;
  align-items: center;

  & svg,
  & svg path {
    width: rem(20px);
    height: rem(20px);
    fill: $primary;
    margin-right: 20px;
  }
}
