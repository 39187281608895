@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-card {
  .zh-card-alert-wrapper {
    &:nth-child(2) {
      border: 1px solid #e9e8e5;
      border-bottom: unset;
    }

    &:nth-last-child(2) {
      border-top: unset;
      border-bottom: unset;
    }

    &:last-child {
      border: 1px solid #e9e8e5;
      border-top: unset;
    }
  }

  .zh-table-mobile {
    .zh-table-mobile-item {
      &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: unset;
      }
    }
  }

  & .zh-card-header {
    padding-top: rem(20px);
  }

  &-btn {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    & button {
      display: flex;
      align-items: center;
      color: $invertActiveBackground !important;

      & svg {
        margin-left: rem(8px);

        &,
        & path {
          fill: $invertActiveBackground;
        }
      }
    }
  }
}

// HEADER
.zh-card-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .zh-card-header-title {
    font-size: rem(14px);
    @include fontSemiBold;
    margin-right: rem(20px);
    white-space: nowrap;
  }

  .zh-card-header-side {
    &-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: right;

      @include mq($until: tablet) {
        text-align: left;

        &.length {
          &-2 {
            width: 100%;

            > * {
              width: 100%;
              margin-left: 0 !important;
            }
          }

          &-3 {
            width: 100%;

            > *:first-child {
              width: 100%;
            }

            > *:not(:first-child) {
              margin-left: 0;

              &:not(last-child) {
                margin-right: rem(20px);
              }
            }
          }
        }
      }

      > * {
        white-space: nowrap;

        &:not(:first-child) {
          margin-left: rem(15px);
        }
      }

      .picker-label {
        margin-right: 0;
      }
    }

    &-left,
    &-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > * {
        margin-bottom: rem(22.5px);
      }
    }
  }

  .zh-button-group,
  .zh-input-wrapper,
  .zh-button-wrapper {
    box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);

    .zh-button-wrapper {
      box-shadow: none;
    }
  }
}

// FOOTER
.zh-card-footer {
  padding: rem(20px);
  align-items: center;
  display: flex;
  border: 1px solid #e9e8e5;
  border-top: unset;

  .per_page {
    width: rem(75px);
  }

  .zh-card-footer-total {
    position: relative;
    white-space: nowrap;
    padding-left: rem(25px);
    margin-right: rem(20px);

    .zh-icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(16.5px);
      cursor: pointer;
      transition: 0.2s;

      &:hover path {
        fill: #739898;
      }

      path {
        transition: 0.2s;
        fill: #00362d;
      }
    }
  }

  .zh-card-footer-side {
    &-left {
      position: relative;

      & .zh-loader-inline {
        display: flex;
        flex-direction: revert;
        flex-wrap: nowrap;
        width: rem(160px);

        .language-ro & {
          width: rem(180px);
        }
        .language-ru & {
          width: rem(170px);
        }
      }
    }
    &-right {
      width: 100%;
      text-align: right;
      margin-left: rem(20px);

      .zh-card-footer-view-more {
        @include fontSemiBold;
        color: #104d43;
        transition: 0.2s;
        cursor: pointer;
        font-size: rem(14px);

        &:hover {
          color: #739898;

          .zh-icon {
            transform: translateX(5px);

            path {
              fill: #739898;
            }
          }
        }

        .zh-icon {
          transition: 0.2s;
          margin-left: rem(5px);
          vertical-align: rem(-1.5px);
          font-size: rem(15px);

          path {
            fill: #104d43;
          }
        }
      }

      .zh-button-wrapper:not(:last-child) {
        margin-right: rem(10px);
      }
    }
  }

  .zh-button-wrapper {
    box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);

    &:not(:last-child) {
      margin-right: rem(10px);
    }
  }
}

.zh-card-alert-wrapper {
  border: 1px solid #e9e8e5;

  .zh-card-alert {
    padding: rem(20px);
    display: flex;
    align-items: center;
    background-color: #fff;

    &.zh-card-alert-type {
      &-info {
        background-color: #f9f9f9;

        .zh-card-alert-label svg path {
          fill: #104d43;
        }
      }

      &-warning {
        background-color: #fffae7;

        .zh-card-alert-label svg path {
          fill: #fda926;
        }
      }
    }

    .zh-card-alert-label {
      position: relative;
      width: 100%;
      font-size: rem(13px);
      @include fontMedium;
      padding-left: rem(25px);

      .zh-icon {
        font-size: rem(16px);
        margin-right: rem(10px);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .zh-report-button {
      white-space: nowrap;
    }
  }
}
