@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-info {
  position: relative;
  @include fontSemiBold;
  font-size: rem(12px);

  &.inline-flex {
    display: inline-flex;
  }

  &:not(:last-child) {
    margin-right: rem(5px);
  }

  &.zh-info {
    &-icon {
      padding-left: rem(20px);
    }

    &-loading {
      transition: color 0.5s linear;
      color: $unactiveText;
    }

    &-text {
      &-regular {
        color: $regularText;
      }
      &-primary {
        color: $invertActiveBackground;
      }
    }
  }

  .zh-info-icon {
    position: absolute;
    left: 0;
    top: rem(1px);

    .zh-icon-clock path {
      fill: #175248;
    }
  }
}
