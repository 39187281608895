@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-collapse-item {
  .zh-collapse-item-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: rem(12px);
    border: rem(1px) solid #e9e8e5;
    border-bottom: none;

    & .collapse-item {
      width: 100%;

      & > div {
        & > div:not(:last-child) {
          margin-right: rem(12px);
        }
      }
    }

    &.open {
      border-bottom: 1px solid $primary;

      & > svg:first-child {
        transform: rotate(0deg);
      }
    }

    & > svg:first-child {
      margin-right: rem(12px);
      transition: transform 300ms;
      transform: rotate(-90deg);
    }
  }

  & .zh-table {
    border-bottom: none;
  }

  & .zh-card-footer {
    border-bottom: 1px solid $primary;
  }

  &:last-child {
    border-bottom: 2px solid $primary;
  }

  &:first-child {
    border-top: 2px solid $primary;
  }
}
