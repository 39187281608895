@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

@keyframes heightAnimation {
  0% {
    max-height: rem(300px);
  }
  100% {
    max-height: 0;
  }
}

.zh-profile {
  width: rem(300px);
  padding: 0 !important;

  .zh-profile-title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e8e5;
    padding: rem(15px);
    @include fontSemiBold;
    font-size: rem(13px);
    color: #3c3939;
  }

  .zh-loader-inline {
    width: 100%;
    text-align: center;
    display: block;
    padding: rem(15px 0);
    border-bottom: 1px solid #e9e8e5;
  }

  .zh-profile-items {
    &.zh-profile-items-roles .zh-profile-item {
      border-bottom: 1px solid #e9e8e5;
      padding-left: rem(67.5px);
      text-transform: capitalize;
    }

    & > div {
      &:nth-child(1) .zh-profile-item-circle {
        background-color: rgba($error, 0.1);

        &.zh-icon-stroked path {
          stroke: $error !important;
        }

        path {
          fill: $error !important;
        }
      }
      &:nth-child(2) .zh-profile-item-circle {
        background-color: rgba($warning, 0.1);

        &.zh-icon-stroked path {
          stroke: $warning !important;
        }

        path {
          fill: $warning !important;
        }
      }
      &:nth-child(3) .zh-profile-item-circle {
        background-color: rgba($success, 0.1);

        &.zh-icon-stroked path {
          stroke: $success !important;
        }

        path {
          fill: $success !important;
        }
      }
    }

    .zh-profile-item {
      display: block;
      cursor: pointer;
      position: relative;
      padding: rem(15px);
      padding-left: rem(55px);
      @include fontSemiBold;
      font-size: rem(13px);
      color: #00362d;
      background-color: #fff;
      transition: 0.2s;

      &.loading {
        background-color: #f4f3f2;
        color: $unactiveText;
      }

      &:hover,
      &.active {
        background-color: #f4f3f2;
        color: #739898;
      }

      .zh-profile-item-circle {
        width: rem(30px);
        height: rem(30px);
        border-radius: 50%;
        background-color: rgba($info, 0.1);
        position: absolute;
        left: rem(20px);
        top: 50%;
        transform: translateY(-50%);

        .zh-icon,
        .zh-loader-spinner {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          &.zh-icon-stroked path {
            stroke: $info;
          }

          path {
            fill: $info;
          }
        }

        .zh-loader-spinner {
          left: calc(50% - (1.04006rem / 2));
          top: calc(50% - (1.04006rem / 2));
        }
      }

      .zh-icon {
        font-size: rem(18px);
        position: absolute;
        left: rem(27.5px);
        top: rem(15px);

        path {
          fill: #00362d;
        }
      }
    }
  }
}
