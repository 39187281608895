@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-session-single {
  display: flex;
  padding: 0 !important;

  &-title {
    padding: rem(30px 30px 0);
    font-size: rem(16px);
  }

  &-total {
    width: auto !important;
    min-width: 30%;
    white-space: nowrap;
    padding: rem(30px 30px 30px 0);

    .zh-session-card-info-label,
    .zh-session-card-info-text {
      color: $regularText !important;
      font-size: rem(20px) !important;
      text-transform: none !important;
    }
  }

  & > .zh-session-card-info:first-child {
    border-right: 1px solid $inputBorder;
  }

  & .zh-session-card-item {
    display: flex;
    border: none !important;
  }

  @include mq($until: desktop) {
    flex-direction: column;

    &-total {
      padding: rem(0 30px 30px 30px);
    }

    & .zh-session-card-item {
      flex-direction: column;
    }

    & > .zh-session-card-info:first-child {
      border-right: none;
      border-bottom: 1px solid $inputBorder;
    }
  }
}
