@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

.zh-avatar {
  position: relative;
  border-radius: rem(4px);
  background-color: #fc9570;

  &.circle {
    border-radius: 50%;

    .zh-avatar-img {
      border-radius: 50%;
    }
  }

  &.zh-avatar-size {
    &-small {
      min-width: rem(30px);
      width: rem(30px);
      height: rem(30px);

      .zh-avatar-short-alt {
        font-size: rem(9px);
      }
    }

    &-big {
      min-width: rem(60px);
      width: rem(60px);
      height: rem(60px);

      .zh-avatar-short-alt {
        font-size: rem(13px);
      }
    }
  }

  .zh-avatar-short-alt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include fontBold;
    color: #fff;
    white-space: nowrap;
  }

  .zh-avatar-img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: rem(4px);
    object-fit: cover;
  }

  .zh-avatar-status {
    position: absolute;
    bottom: rem(-2.5px);
    right: rem(-2.5px);
    width: rem(10px);
    height: rem(10px);
    border: 2px solid #fff;
    border-radius: 50%;

    &.zh-avatar-status {
      // REF: STATUS.SCSS

      &-active {
        background: #469c9b;
      }

      &-regular {
        background: #fc9570;
      }

      &-uanctive {
        background: #767676;
      }
    }
  }
}

.zh-avatar-card {
  display: inline-flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #dfddd6;
  border-radius: rem(4px);

  .zh-avatar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: rem(23px);
    width: rem(23px);

    img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .zh-avatar-card-alt {
    color: #333;
    padding: rem(0 7.5px);
    border-top-right-radius: rem(4px);
    border-bottom-right-radius: rem(4px);
    @include fontMedium;
    font-size: rem(9px);
  }
}

.zh-avatar-inline {
  display: flex;
  align-items: center;

  .zh-avatar-inline-alt {
    margin-left: rem(15px);
    @include fontSemiBold;
    font-size: rem(12px);
    color: #000403;
  }

  .zh-avatar-inline-role {
    font-size: rem(10px);
    color: $titleText;
    text-transform: capitalize;
  }
}
