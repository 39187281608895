@import 'styles/palette';
@import 'styles/helpers';

@include fadeBackground('checkedFade', #d1e0e0);
@include fadeBackground('checkedDotFade', #469c9b);

.zh-switch {
  border-radius: rem(25px);
  height: rem(20px);
  width: rem(45px);
  position: relative;
  background: #b5b4b3;
  cursor: pointer;

  &.zh-switch {
    &-unchecked {
      .zh-icon-check {
        transform: translate(-50%, -200%);
      }

      .zh-icon-cancel {
        transform: translate(-50%, -50%);
      }

      .zh-switch-dot {
        path {
          fill: $invertActiveBackground;
        }
      }
    }

    &-checked {
      .zh-icon-check {
        transform: translate(-50%, -50%);
      }

      .zh-icon-cancel {
        transform: translate(-50%, 100%);
      }

      .zh-switch-dot {
        transform: translate(22.5px, -50%);
        animation: checkedDotFade 0.25s forwards;
      }
    }
  }

  .zh-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: 0.5s;
  }

  .zh-switch-dot {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: rem(24px);
    height: rem(24px);
    padding: rem(5px);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 1px 10px 0px rgba(0, 82, 204, 0.1), 0px 2px 9.8px 0.2px rgba(0, 82, 204, 0.16);
    transition: 0.3s;
    overflow: hidden;
  }

  .zh-switch-checked-sheet {
    border-radius: rem(25px);
    animation: checkedFade 1s forwards;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
