@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

// animations
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.zh-loader-spinner-type-simple {
  width: rem(40px);
  height: rem(40px);
  border-radius: 50%;

  &.zh-loader-spinner-size {
    &-small {
      border: 2px solid $unactive;
      border-top: 2px solid $primary;
      width: rem(15px);
      height: rem(15px);
      top: calc(50% - 5px);
    }

    &-middle {
      border: 2px solid $unactive;
      border-top: 2px solid $primary;
      width: rem(20px);
      height: rem(20px);
      top: calc(50% - 10px);
    }

    &-regular {
      border: 6px solid $unactive;
      border-top: 6px solid $primary;
      width: rem(60px);
      height: rem(60px);
      top: calc(50% - 20px);
    }
  }
}

.zh-loader-spinner-type-simple {
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.zh-loader-inline {
  color: $unactiveText;
  @include fontMedium;
  font-size: rem(14px);

  .zh-loader-spinner {
    position: relative;
    top: rem(2px);
    display: inline-block;
    margin-right: rem(7.5px);
  }
}

.zh-loader {
  position: relative;
  animation: loaderFade 0.5s forwards;
  z-index: 2;

  .zh-loader-spinner {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    &.fixed {
      position: fixed;
    }
  }
}
