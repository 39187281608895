@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-see-other {
  position: relative;
  border-color: $inputBorder !important;
  border-left: rem(3px) solid $primary !important;

  & span {
    width: rem(20px);
    height: rem(20px);
    border: rem(1px) solid $inputBorder;
    color: $primary;
    border-radius: rem(100px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: rem(10px);
    font-size: rem(10px);
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & svg {
    &,
    & path {
      fill: $invertActiveBackground;
    }

    &:first-child:not(:last-child) {
      margin-right: rem(22.5px);
      font-size: rem(20px) !important;
    }

    &:last-child:not(:first-child) {
      transform: rotate(-90deg);
      position: absolute;
      right: rem(22.5px);
    }
  }
}
