@import 'styles/helpers';

.zh-permissions-guard {
  text-align: center;

  p {
    text-align: center;
    margin: rem(25px 0 0);
    padding: 0;
    font-size: rem(16px);
  }
}
