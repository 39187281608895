@import 'styles/helpers';

.grade-item {
  margin-top: rem(15px);

  .grade-item-old {
    background: transparent !important;
    border-style: dashed !important;
    pointer-events: none;
  }

  .zh-form-item {
    margin: 0 !important;

    &:not(:last-child) {
      margin-bottom: rem(20px) !important;
    }
  }
}
