@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/palette';
@import 'styles/media-queries';

@keyframes moveRightOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(0);
    opacity: 1;
  }
}

.zh-form {
  .zh-form-items {
    display: flex;
    justify-content: space-between;

    @include mq($until: small-desktop) {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: rem(20px);
    }

    &.zh-form-items-three {
      .zh-form-item {
        width: calc(33% - 7.5px);
      }
    }

    .zh-form-item {
      @include mq($from: small-desktop) {
        margin-bottom: 0 !important;

        &:not(:first-child) {
          margin-left: rem(12.5px);
        }
      }

      @include mq($until: small-desktop) {
        width: 100% !important;
      }
    }
  }

  .zh-form-item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: rem(20px);
    }

    .zh-form-item-label {
      display: flex;
      @include fontMedium;
      color: #000403;
      font-size: rem(12px);
      margin-bottom: rem(7.5px);
      text-align: left;

      & span {
        color: $error;
      }
    }

    .zh-form-children.zh-form-children-flex {
      display: flex;

      @include mq($until: 540px) {
        flex-wrap: wrap;
      }

      > * {
        width: 100%;

        &:not(:first-child) {
          @include mq($from: 540px) {
            margin-left: rem(25px);
          }
        }
      }
    }

    .zh-form-extra {
      @include fontMedium;
      margin-top: rem(5px);
      padding: rem(2px);
      color: #000403;
      font-size: rem(12px);
      text-align: right;
      border-radius: rem(4px);

      animation: moveRightOut 0.5s normal;

      &.zh-form-extra {
        &-error {
          color: $invertRegularText;
          background-color: #ff4000;
        }

        &-warning {
          color: $invertRegularText;
          background-color: $warning;
        }

        &-regular {
          color: $unactiveText;
        }

        &-success {
          color: $invertRegularText;
          background-color: #00ad2e;
        }
      }
    }
  }
}
