@import 'styles/media-queries';

.download-report-mobile {
  display: none;
}

@include mq($until: desktop) {
  .download-report-mobile {
    display: block;
  }
}
