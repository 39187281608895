@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-tabs {
  margin: rem(20px 0);
  border: none;
  border-bottom: 1px solid #e9e8e5;

  .rc-tabs-tab-btn {
    outline-color: transparent;
  }

  .rc-tabs-ink-bar {
    display: none;
  }

  .rc-tabs-nav-wrap {
    &:before {
      border-left-color: #e9e8e5;
    }

    &:after {
      border-right-color: #e9e8e5;
    }
  }

  .rc-tabs-nav-list {
    .rc-tabs-tab {
      cursor: inherit;

      &:not(:last-child) .zh-tabs-item {
        margin-right: rem(35px);
      }
    }
  }

  .rc-tabs-nav-more {
    position: relative;
    background: transparent;
    border: none;
    color: transparent;
    outline-color: transparent;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url('../../../resources/arrow-down.svg');
      background-size: 11px 6px;

      // keep it in pixels
      height: 6px;
      width: 11px;
    }
  }
}

.zh-tabs .zh-tabs-item,
.rc-tabs-dropdown-menu {
  outline-color: transparent;

  &.zh-tabs-item {
    &-active .zh-tabs-item-label {
      border-bottom-color: #104d43;
      color: #00362d;

      &:-webkit-any-link {
        color: #00362d;
      }
    }

    &-unactive .zh-tabs-item-label {
      color: #3c3939;

      &:-webkit-any-link {
        color: #3c3939;
      }
    }

    &-disabled .zh-tabs-item-label {
      color: $unactive2 !important;
      border-bottom-color: transparent !important;
      cursor: not-allowed !important;

      &:-webkit-any-link {
        color: $unactive2;
      }
    }
  }

  .zh-tabs-item-label {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    @include fontSemiBold;
    font-size: rem(14px);
    padding: rem(5px 0);
    border-bottom: 3px solid transparent;
    cursor: pointer;
    transition: 0.2s;

    .count {
      height: rem(20px);
      min-width: rem(20px);
      margin-left: rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: #739898;
      border-bottom-color: #739898;
    }
  }
}

.rc-tabs-dropdown {
  min-width: rem(150px) !important;
  box-shadow: 0px 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
  border: 1px solid #e9e8e5;
  border-radius: rem(4px);
  outline-color: transparent;

  .zh-tabs-item-active .zh-tabs-item-label {
    color: #469c9b;
  }

  .zh-tabs-item-label {
    padding: rem(5px 0);
    border-bottom: unset;
  }
}
