@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.pagination {
  width: 100%;
  margin-top: rem(20px);
  padding: rem(0 20px);

  &-buttons {
    display: flex;

    & .zh-button-wrapper:first-child {
      margin-right: rem(20px);
    }
  }
}
