@import 'styles/helpers';
@import 'styles/palette';

.zh-icon {
  &.zh-icon-size {
    &-small {
      font-size: rem(14px);
    }

    &-big {
      font-size: rem(45px);
    }
  }

  &.disabled {
    cursor: not-allowed;

    path {
      fill: $unactiveText;
    }
  }
}
