@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-edit-account {
  border: 1px solid #e9e8e5;
  display: flex;

  @include mq($until: desktop) {
    flex-wrap: wrap-reverse;
  }

  &.zh-edit-account-modal {
    border: inherit;
    display: block;

    .zh-edit-account-left {
      border-right: inherit;
      padding: rem(10px 37.5px 15px);

      h2:not(:first-child):not(.block) {
        display: none;
      }

      h3 {
        border-top: inherit;
      }

      .zh-input-wrapper,
      .rc-select,
      .zh-rich-text {
        background-color: #fff;
      }
    }
  }

  .zh-edit-account-left,
  .zh-edit-account-right {
    width: 100%;
    padding: rem(37.5px);
  }

  .zh-edit-account-left {
    border-right: 1px solid #e9e8e5;

    @include mq($until: desktop) {
      border-right: unset;
    }

    .zh-rich-text {
      background-color: #f9f9f9;
    }

    h2,
    h3 {
      margin-bottom: rem(20px);
      @include fontSemiBold;
      color: #414141;
      font-size: rem(14px);

      &:not(:first-child) {
        border-top: 1px solid #e9e8e5;
        padding-top: rem(20px);
      }
    }

    .picker-label {
      max-width: 100%;
    }
  }

  .zh-edit-account-right {
    background-color: #f9f9f9;

    @include mq($until: desktop) {
      border-bottom: 1px solid #e9e8e5;
    }
  }
}

.zh-edit-account-bottom {
  border: 1px solid #e9e8e5;
  border-top: unset;
  padding: rem(20px);
  text-align: center;

  > *:not(:first-child) {
    margin-left: rem(15px);
  }
}
