@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.select {
  position: relative;

  &.select-cancelable {
    .rc-select-selector {
      padding-right: 30px !important;
    }

    &:hover {
      .zh-select-close {
        display: block;
      }

      .rc-select-arrow {
        display: none;
      }
    }
  }

  .zh-select-close {
    display: none;
    position: absolute;
    right: rem(10px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;

    &:hover .zh-icon path {
      fill: $primary !important;
    }

    .zh-icon {
      font-size: rem(11px);

      path {
        transition: 0.3s;
      }
    }
  }
}

.rc-select {
  width: 100%;
  border: 1px solid $unactive;
  background-color: $activeBackground;
  font-size: rem(13px);
  border-radius: rem(4px);

  &.select-style-grey {
    background-color: #f9f9f9;
  }

  &.rc-select-multiple {
    .rc-select-selector {
      align-items: center;
      padding: rem(0 5px) !important;

      .rc-select-selection-search-input {
        @include fontRegular;
        background: transparent;
      }

      .rc-select-selection-item {
        top: inherit !important;
        left: inherit !important;
        position: relative;
        font-size: rem(12px);
        padding: rem(5px 15px 5px 5px);
        background: transparent;
        border: 1px solid #dfded8;
        height: max-content;
        margin: rem(2px 0);

        &:not(:last-child) {
          margin-right: rem(5px);
        }

        .rc-select-selection-item-remove {
          position: absolute;
          right: rem(2.5px);
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
          font-size: rem(16px);
          height: 100%;
          display: flex;
          align-items: center;

          span {
            display: block;
          }
        }
      }
    }
  }

  .rc-select-arrow {
    transition: 0.3s;
    right: rem(10px);
    top: rem(7.5px);
  }

  .rc-select-selector,
  .rc-select-selection-search-input {
    padding: rem(0 12.5px);
    border: none !important;
    min-height: rem(32.5px);
    background: transparent !important;

    &:not(.rc-select-selection-search-input) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .rc-select-selection-placeholder,
  .rc-select-selection-item {
    @include fontRegular;
    top: rem(9px) !important;
    left: rem(12.5px) !important;

    & span.grade,
    & span.number {
      display: none;
    }
  }

  .rc-select-selection-placeholder {
    position: absolute;
    opacity: 1;
    color: $unactiveText;

    & .zh-loader-inline {
      padding-left: rem(25px);

      & .zh-loader-spinner {
        top: 0;
        left: 0;
      }
    }
  }

  .rc-select-selection-item {
    opacity: 1;
    color: $regularText;

    .zh-input-phone & {
      display: flex;
      align-items: center;
      top: 50% !important;
      transform: translate3d(0, -50%, 0);
    }

    & .zh-input-phone-country {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }
  }
}

.rc-select-dropdown {
  border: 1px solid $regularBorder;
  min-height: rem(48px);
  position: absolute;
  background: $activeBackground;
  border-radius: rem(4px);
  z-index: 9999;

  .rc-select-item {
    @include fontRegular;
    padding: rem(7.5px 7.5px 7.5px 40px);
    cursor: pointer;
    font-size: rem(12px);
    cursor: pointer;

    & .rc-select-item-option-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & span.number {
        float: right;
        margin-right: rem(5px);
        font-size: rem(10px);
        color: $unactiveText;
      }

      & span.grade {
        float: right;
        margin-right: 10px;
        background: #f1f1f1;
        width: 18px;
        text-align: center;
        border-radius: 100px;

        &[aria-experience='1'],
        &[aria-experience='2'] {
          background: $error;
          color: #fff;
        }

        &[aria-experience='3'] {
          background: $warning;
        }

        &[aria-experience='4'] {
          background: $info;
          color: #fff;
        }

        &[aria-experience='5'] {
          background: $success;
        }
      }
    }

    &:not(:first-child) {
      border-top: 1px solid $regularBorder;
    }

    &.rc-select-item-option.recommended {
      background: rgba($warning, 0.1);
    }

    &.rc-select-item-option-active {
      background: $secondaryBackgroundHalf;

      &.recommended {
        background: rgba($warning, 0.5);

        & .rc-select-item-option-state {
          background: $warning;
          border-color: $warning;
          filter: brightness(0.75);
        }
      }
    }

    &.rc-select-item-option-selected {
      background: $activeBackground;

      &.recommended {
        & .rc-select-item-option-state {
          background: $warning;
          border-color: $warning;
          filter: brightness(0.75);
        }
      }

      &.rc-select-item-option-active,
      &:hover {
        background: $secondaryBackground;

        &.recommended {
          background: $warning;
        }
      }

      .rc-select-item-option-state {
        transition: 0.3s ease-out;
        box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);
        background: $invertActiveBackground;
        border-color: $invertActiveBackground;

        .rc-select-item-option-state-icon {
          color: $invertRegularText;
          font-size: rem(12px);
        }
      }
    }

    .rc-select-item-option-state {
      right: inherit;
      top: 50%;
      transform: translateY(-50%);
      left: rem(12.5px);
      width: rem(16px);
      height: rem(16px);
      border-radius: rem(4px);
      background: $unactiveBackground;
      border: rem(1px) solid $secondaryBorder;
      transition: 0.3s;

      .rc-select-item-option-state-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: rem(0);
        transition: 0.3s;
      }
    }
  }

  &.hideCheck {
    & .rc-select-item {
      padding: rem(15px);
      border-color: $inputBorder;

      &:hover {
        background: $inputBorder;
      }
    }
    & .rc-select-item-option-state {
      display: none;
    }
  }

  &.phone {
    min-width: 100px !important;

    & .rc-select-item {
      padding: rem(10px);

      &-option-state {
        display: none;
      }
      &-option-content {
        display: flex;
        align-items: center;
      }
    }
  }
}
