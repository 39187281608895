.zh-report-button {
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);

  .zh-button-prefix {
    top: calc(50% + 1px);

    svg path {
      fill: #fda926 !important;
    }
  }
}
.zh-report-button__link {
  cursor: pointer;
  color: #723b97;
}
.zh-report {
  text-align: left;
}
