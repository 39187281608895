@import './normalize', './helpers', './media-queries', './palette', './typography';

// @include mq-show-breakpoints;

html,
body {
  display: block;
  font-size: rem(13px);
  @include fontMedium;
  color: $regularText;

  @include mq($until: desktop) {
    font-size: rem(12.5px);
  }

  @include mq($until: small-desktop) {
    font-size: rem(10px);
  }

  ::-webkit-scrollbar {
    width: rem(24px);
    height: rem(24px);
    background: $inputBorder;
    border: rem(11px) solid #fff;
  }

  ::-webkit-scrollbar-thumb {
    height: rem(100px);
    border: rem(8px) solid transparent;
    background-clip: padding-box;
    background-color: $primary;
    box-shadow: inset rem(-1px -1px 0px) rgba(0, 0, 0, 0.05), inset rem(1px 1px 0px) rgba(0, 0, 0, 0.05);

    &:active {
      background-color: $invertActiveBackground;
    }
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

* {
  box-sizing: border-box;
}

.sb-show-main {
  padding: rem(25px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: rem(16px);
  @include fontSemiBold;
  margin-bottom: rem(20px);
}

h2 {
  font-size: rem(14px);

  &.medium {
    @include fontMedium;
    font-size: rem(14px);
  }
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.grey {
  color: $unactiveText;

  &-svg path {
    fill: $unactiveText !important;
  }
}

.disabled {
  cursor: not-allowed !important;
}

a {
  text-decoration: none;
}

textarea {
  width: 100%;
  border: 1px solid $inputBorder;
  padding: rem(12.5px);
  min-height: rem(50px);
  outline: none;
  border-radius: rem(4px);
}

hr {
  border-bottom: none;
  border-top: rem(1px) solid $inputBorder;
}

hr.hr {
  margin: rem(20px -20px 20px);

  &-2 {
    margin: rem(20px 0);
  }
}

.hide {
  display: none;
}

.hideTablet {
  @include mq($until: tablet) {
    display: none;
  }
}

.pointer {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.primary {
  & svg,
  & svg path {
    fill: $titleText !important;
  }

  & button {
    color: $titleText !important;
  }
}

.nowrap {
  white-space: nowrap !important;
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-justify-end {
    justify-content: flex-end;
  }

  &-align-end {
    height: 100%;
    align-items: flex-end;
  }

  &-end {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &-start {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-nowrap {
    flex-wrap: nowrap !important;
  }

  &-margin-between {
    > *:not(:first-child) {
      margin-left: rem(15px);
    }
  }

  &-between {
    justify-content: space-between;
  }

  &-center {
    justify-content: center;
  }

  &-column {
    flex-direction: column;

    > *:not(:first-child) {
      margin: rem(10px) 0 0 !important;
    }
  }

  &-data {
    display: flex;
    flex-direction: column;
    border: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;

    &.expanded {
      width: 100%;
      margin: rem(0);
      border: none;
      border-bottom: none;
      overflow: hidden;

      & .zh-info {
        display: flex;
        flex-direction: column;
        font-size: rem(12px);
      }
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    padding: rem(15px 25px 0);
    border-bottom: 1px solid $inputBorder;

    &-empty {
      padding: rem(10px 0);
      text-align: center;
    }

    & .zh-calendar {
      margin-right: rem(20px);

      @include mq($until: small-desktop) {
        width: fit-content;
        padding: rem(5px 0);
      }

      & .zh-calendar-date {
        width: auto;
      }
    }

    &.column {
      flex-direction: column;
      padding: 0;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary !important;
    }

    & img {
      display: flex;
      width: rem(190px);
      height: auto;
      margin-right: rem(20px);
      margin-bottom: rem(20px);
      border-radius: rem(4px);
      pointer-events: none;
      user-select: none;
    }

    & > p {
      margin-top: rem(20px);
    }

    & svg,
    & path {
      fill: $primary;
    }

    &-map {
      padding: 0;
      border: none;
    }

    &-row {
      width: 100%;
      padding-right: rem(20px);

      &-small {
        & .zh-info {
          font-size: 12px !important;
        }
      }

      &.column {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: rem(10px 77.5px);

        & > * {
          display: flex;
          justify-content: space-around;

          &:first-child {
            display: flex;
            justify-content: flex-start;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }

        &:nth-child(2n + 2) {
          background: #f9f9f9;
        }
      }

      &-col {
        display: flex;
        flex-wrap: wrap;

        &-grades {
          border: 1px solid $primary;
          border-bottom: none;
          padding: 15px 10px 10px;
          margin: -15px -10px -10px;

          &:first-child {
            border-top: none;
          }
        }

        &.column {
          flex-direction: column;
        }

        & > * {
          flex: 0 0 33%;
          margin-bottom: rem(15px);

          @include mq($until: desktop) {
            flex: 0 0 50% !important;
          }

          @include mq($until: small-tablet) {
            flex: 0 0 100% !important;
          }

          &:not(:last-child) {
            padding-right: rem(15px);
          }
        }
      }

      & .zh-info {
        font-size: rem(14px);

        &:first-child {
          font-size: rem(16px);
        }
      }
    }

    & label {
      display: flex;
      font-size: rem(13px);
      color: $labelText;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }

    &-actions {
      white-space: nowrap;

      & > div {
        &:first-child {
          margin-right: rem(15px);
        }
      }
    }

    @include mq($until: desktop) {
      position: relative;

      &-map &-row {
        &-col {
          flex-wrap: wrap;
        }
      }

      &-row {
        &-col {
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:not(:first-child):last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;

      &-map &-actions {
        margin-top: 0;
      }
      &-actions {
        margin: rem(25px 0);
        display: flex;
        justify-content: center;
      }

      &-row {
        &-col {
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }
  }
}

.pa {
  &-0 {
    padding: 0 !important;
  }
}

.pt {
  &-0 {
    padding-top: 0;
  }

  &-5 {
    padding-top: rem(5px);
  }
}

.block {
  display: block;
}

.ml {
  &-15 {
    margin-left: rem(15px);
  }
}

.mr {
  &-10 {
    margin-right: rem(10px);
  }

  &-15 {
    margin-right: rem(15px);
  }
}

.mt {
  &-0 {
    margin-top: rem(0px);
  }

  &-5 {
    margin-top: rem(5px);
  }

  &-10 {
    margin-top: rem(10px);
  }

  &-15 {
    margin-top: rem(15px);
  }

  &-20 {
    margin-top: rem(20px);
  }

  &-25 {
    margin-top: rem(25px);
  }

  &-30 {
    margin-top: rem(30px);
  }

  &-35 {
    margin-top: rem(35px);
  }

  &-40 {
    margin-top: rem(40px);
  }
}

.mb {
  &-0 {
    margin-bottom: rem(0px);
  }

  &-5 {
    margin-bottom: rem(5px);
  }

  &-10 {
    margin-bottom: rem(10px);
  }

  &-15 {
    margin-bottom: rem(15px);
  }

  &-20 {
    margin-bottom: rem(20px);
  }

  &-25 {
    margin-bottom: rem(25px);
  }

  &-30 {
    margin-bottom: rem(30px);
  }

  &-35 {
    margin-bottom: rem(35px);
  }
}

.text-transform {
  &-inherit {
    text-transform: inherit;
  }

  &-uppercase {
    text-transform: uppercase;
  }
}

.mx-w {
  &-720 {
    max-width: rem(720px);
  }

  &-660 {
    max-width: rem(660px);
  }

  &-330 {
    max-width: rem(330px);

    @include mq($until: tablet) {
      max-width: 100%;
    }
  }
}

.h {
  &-25 {
    height: rem(25px);
  }
}

.vertical-align {
  &-top {
    vertical-align: top;
  }

  &-bottom {
    vertical-align: bottom;
  }
}

.z {
  &-1 {
    z-index: 1;
  }

  &-10 {
    z-index: 10;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
}

.position {
  &-fixed {
    position: fixed;
  }

  &-relative {
    position: relative;
  }
}

div[data-name='mojs-shape'] {
  z-index: 1;
}

.toast-notification {
  z-index: 10000 !important;
}

.width-auto {
  width: auto !important;
}

.document {
  h2 {
    &,
    & * {
      font-size: rem(24px);
      line-height: rem(40px);
    }

    &:not(:first-child) {
      margin-top: rem(10px);
    }
  }

  &-link {
    margin: rem(0 4px);
    color: $primary;
  }

  span + a {
    margin: rem(0 4px);
  }

  p,
  li {
    line-height: rem(30px);
  }

  ul {
    margin: 0;
  }
}
