@import './variables';

// animations
@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// A mixin that converts from px to rem
@function rem-separator($list) {
  @if function-exists('list-separator') ==true {
    @return list-separator($list);
  }
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }
  @return if($test-list==$list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to== 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to== 'rem' {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }
  @return if(length($result) ==1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

// skeleton loader
@mixin skeleton {
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton,
.skeleton-auto {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  @include skeleton;
}

.skeleton-input {
  color: transparent !important;

  ~ .input-wrapper {
    color: transparent !important;
  }

  svg path {
    fill: transparent !important;
  }
}

.skeleton-fluid {
  @include skeleton;
}

.skeleton-auto {
  height: auto !important;
}

.skeleton-text {
  color: transparent !important;
}

.skeleton-img {
  filter: blur(5px) grayscale(100%) !important;
}

// pulse
@mixin pulse($name, $color) {
  @keyframes #{$name} {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba($color, 0.7);
      box-shadow: 0 0 0 0 rgba($color, 0.7);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba($color, 0);
      box-shadow: 0 0 0 10px rgba($color, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba($color, 0);
      box-shadow: 0 0 0 0 rgba($color, 0);
    }
  }
}

// fade
@mixin fadeBackground($name, $color) {
  @keyframes #{$name} {
    from {
      background: rgba($color, 0);
    }
    to {
      background: rgba($color, 1);
    }
  }
}

@mixin fadeExactBackground($name, $color) {
  @keyframes #{$name} {
    from {
      background: rgba($color, 0);
    }
    to {
      background: $color;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.25s forwards;
}

.fade-in-slowly {
  animation: fadeIn 1s forwards;
}

@mixin hiddenScroll {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

td {
  .zh-info {
    .edit-grade {
      .grade {
        display: flex;

        button svg path {
          fill: #eaf0f6 !important;
        }
      }
    }
  }
}

.student-table {
  .actions-col {
    text-align: right !important;
  }

  td.rc-table-cell {
    padding-left: rem(17px);
    padding-right: rem(17px);
  }
}

.text-center {
  text-align: center;
}
