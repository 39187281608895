@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.informativePanel {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 1 100%;

    &:nth-child(1) {
      flex: 1 1 50%;
      padding-right: rem(15px);
    }

    &:nth-child(2) {
      flex: 1 1 50%;
      padding-left: rem(15px);
    }
  }
}

.dateCard {
  width: 100%;
  margin-bottom: rem(15px);

  .flex-data {
    display: flex;
    flex-direction: column;
    border: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;
    width: inherit;

    .flex-item {
      display: flex;
      justify-content: space-between;
      padding: rem(25px);
      width: 100%;
      border-bottom: 1px solid $inputBorder;

      &-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-right: 0;
        width: 100%;

        &-calendar {
          margin-right: rem(5px);
        }

        &-info:not(:first-child):last-child {
          width: auto;
        }

        &-info {
          margin-left: rem(15px);
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > *:not(label) {
            margin-bottom: rem(5px) !important;
            font-size: rem(16px);
          }

          label {
            color: $labelText;
            line-height: rem(20px);
            font-weight: 400;
          }

          #innerHTML {
            color: grey;
            font-size: rem(14px);
            font-weight: 400;
          }
        }
      }
    }
  }
}
