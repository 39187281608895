@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

@include fadeExactBackground('fadeMask', rgba(0, 0, 0, 0.4));

.sb-show-main {
  .zh-layout {
    margin: -1rem;
  }
}

.zh-layout {
  &.zh-layout-state-toggled {
    .zh-layout-left-bar {
      overflow: hidden;
      width: rem(57px);

      .zh-layout-left-bar-logo {
        width: rem(47.5px);
      }

      .zh-icon-arrow-right {
        display: none;
      }

      &-item {
        white-space: nowrap;
      }
    }

    .zh-layout-content-wrapper,
    .zh-layout-footer,
    .zh-layout-top-bar {
      // 57px
      width: calc(100% - 3.95224rem);
    }
  }

  @include mq($until: small-desktop) {
    .zh-layout-content-wrapper,
    .zh-layout-footer,
    .zh-layout-top-bar {
      width: 100% !important;
    }

    .zh-layout-mobile-mask {
      display: block !important;
    }

    .zh-layout-top-bar {
      padding-left: 0 !important;
      z-index: 3;

      .zh-layout-top-bar-mobile {
        display: flex !important;
        align-items: center;
        width: 100%;

        .zh-layout-top-bar-mobile-toggler {
          position: relative;
          height: rem(58px);
          width: rem(58px);
          border-right: 1px solid #e9e8e5;
          cursor: pointer;

          &:hover {
            background-color: #f4f3f2;
          }

          .zh-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .zh-layout-top-bar-title,
    .zh-profile-avatar-left {
      display: none;
    }

    &.zh-layout-mobile {
      &-closed {
        .zh-layout-left-bar {
          transition: 0.2s;
          transform: translateX(-100%);
        }
      }

      &-opened {
        .zh-layout-left-bar {
          transition: 0.2s;
          width: rem(250px) !important;
          max-width: 80%;
          font-size: rem(14px);
          transform: translateX(0);
        }
      }
    }
  }

  .zh-layout-mobile-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: none;
    animation: fadeMask 500ms forwards;
    z-index: 4;
  }

  .zh-layout-top-bar,
  .zh-layout-left-bar,
  .zh-layout-footer {
    background-color: #fff;

    &-top,
    &-bottom {
      user-select: none;
    }

    &-top {
      max-height: calc(100% - 114px);
      overflow: hidden auto;
    }
  }

  .zh-layout-top-bar {
    right: 0;
    top: 0;
    position: fixed;
    border-bottom: 1px solid #e9e8e5;
    height: rem(58px);
    display: flex;
    align-items: center;
    padding: rem(12.5px 30px);
    transition: width 0.2s;
    z-index: 3;

    // 210px
    width: calc(100% - 14.56089rem);

    .zh-layout-top-bar-mobile-logo {
      background-image: url('../../../resources/logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: rem(86px);
      height: rem(22px);
      margin: rem(0 20px);
    }

    .zh-layout-top-bar-mobile {
      display: none;
    }

    .zh-layout-top-bar-title {
      font-size: rem(16px);
      @include fontSemiBold;
      width: 100%;
    }

    .zh-layout-top-bar-left {
      width: 100%;

      ~ .zh-layout-top-bar-title {
        width: auto;
      }
    }

    .zh-layout-top-bar-left,
    .zh-layout-top-bar-right {
      margin-left: rem(15px);
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .zh-layout-top-bar-right > *:not(:first-child) {
      margin-left: rem(20px);
    }

    .zh-layout-top-bar-right-item {
      display: flex;
      align-items: center;
      margin-left: rem(15px);

      > *:not(:first-child) {
        margin-left: rem(15px);
      }
    }
  }

  .zh-layout-left-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: rem(210px);
    height: 100vh;
    border-right: 1px solid #e9e8e5;
    transition: width 0.2s;
    background: #fff;
    z-index: 5;

    @include mq($until: small-desktop) {
      padding-top: rem(80px);

      .zh-layout-left-bar-toggler {
        display: none !important;
      }
    }

    .zh-layout-left-bar-logo {
      background-image: url('../../../resources/logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: rem(128px);
      height: rem(32px);
      margin: rem(25px auto);
      transition: width 0.2s;

      @include mq($until: small-desktop) {
        width: rem(146px);
        height: rem(36px);
      }
    }
  }

  .zh-layout-content-wrapper,
  .zh-layout-footer {
    transition: width 0.2s;
    margin-left: auto;

    // 210px
    width: calc(100% - 14.56089rem);
  }

  .zh-layout-content-wrapper {
    // 4.02158rem
    padding-top: rem(58px);

    // 117.5px
    min-height: calc(100vh - 4.02158rem);

    .zh-layout-content {
      max-width: rem(1510px);
      width: 100%;
      margin: 0 auto;
      padding: rem(15px 25px);

      .zh-breadcrumbs-wrapper {
        margin-bottom: rem(20px);
      }

      & .zh-breadcrumbs-wrapper + .zh-card {
        .zh-card-header {
          padding-top: 0;
        }
      }
    }
  }

  .zh-layout-footer {
    height: rem(58px);
    display: flex;
    align-items: center;

    span {
      display: block;
      margin: 0 auto;
    }
  }

  & .rc-select-show-arrow {
    & .rc-select-arrow {
      right: rem(9px);
    }

    & .rc-select-arrow-icon::after {
      width: rem(12px);
      height: rem(14px);
      border: none;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 12' width='1em' height='1em' %3E%3Cg%3E%3Cg%3E%3Cpath fill='%2300362d' d='M.594 7.959l.943-.953 3.057 3.088L7.65 7.006l.944.953-4 4.04zm8-3.918l-.944.953-3.056-3.088-3.057 3.088-.943-.953 4-4.04z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        no-repeat;
      background-size: contain;
      transform: translateY(3px);
    }
  }

  .zh-icon-chat {
    font-size: rem(19px) !important;
  }
}

.zh-layout .zh-layout-left-bar {
  .zh-layout-left-bar-bottom {
    background-color: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: rem(20px);
    padding-bottom: rem(20px);

    @include mq($until: small-desktop) {
      padding-bottom: rem(120px);
    }

    .zh-layout-left-bar-item.zh-layout-left-bar-item-invert {
      margin: 0;

      &.zh-user-settings {
        padding-top: rem(25px);
        padding-bottom: rem(25px);
      }

      &:hover .zh-icon-question path,
      &.active .zh-icon-question path {
        fill: #3975b2;
      }

      .zh-icon-user {
        font-size: rem(32px) !important;
        left: rem(12.5px);
      }
    }
  }

  .zh-layout-left-bar-item {
    position: relative;
    cursor: pointer;
    padding: rem(15px);
    transition: 0.3s;
    padding-left: rem(57.5px);
    padding-right: rem(27.5px);
    display: flex;
    align-items: center;
    color: #11433b;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: rem(44px);

    span {
      max-width: rem(120px);
    }

    .attention {
      position: absolute;
      left: rem(32px);
      top: rem(7.5px);
      fill: $error;
    }

    .count {
      margin-left: 10px;
    }

    a {
      color: #11433b;
    }

    .zh-icon:first-child {
      position: absolute;
      left: rem(20px);
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(18px);

      @include mq($until: small-desktop) {
        font-size: rem(20px);
      }

      rect {
        transition: 0.3s;
        fill: #104d43;
      }

      path {
        transition: 0.3s;
        fill: #104d43;
      }
    }

    .zh-icon-arrow-right {
      position: absolute;
      right: rem(10px);
      top: 50%;
      font-size: rem(18px);
      opacity: 0;
      transform: translate(-15px, -50%);
      transition: 0.1s;

      rect {
        fill: #739898;
      }

      path {
        fill: #739898;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: rem(3px);
      height: 100%;
      transform: translateX(-100%);
      background: #fc9570;
      transition: 0.4s;
    }

    &:hover,
    &.active {
      background: #104d43;
      color: #fff;

      &:after {
        transform: translateX(0);
      }

      .zh-icon {
        &.zh-icon-stroked:first-child path {
          stroke: #fff;
        }

        &:first-child {
          rect {
            fill: #fff;
          }

          path {
            fill: #fff;
          }
        }

        &.zh-icon-graduation {
          path {
            stroke: #fff !important;
          }
        }
      }

      .zh-icon-arrow-right {
        opacity: 1;
        transform: translate(0, -50%);
        font-size: rem(12px);
      }
    }

    &.zh-layout-left-bar-item-invert {
      margin: rem(20px 0);

      &:after {
        background-color: #47675c;
      }

      &:hover,
      &.active {
        color: #537066;
        background-color: #f4f3f2;

        .zh-icon path {
          fill: #47675c;
        }
      }

      .zh-icon-arrow-right {
        display: none;
      }
    }
  }
}

.zh-profile-avatar {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover .zh-profile-avatar-left .zh-profile-avatar-left-name {
    color: #739898;
  }

  .zh-profile-avatar-left {
    text-align: right;
    margin-right: rem(12px);
    user-select: none;

    .zh-profile-avatar-left-name {
      font-size: rem(13px);
      @include fontSemiBold;
      color: #104d43;
      margin-bottom: rem(2.5px);
      transition: 0.2s;
      white-space: nowrap;
    }

    .zh-profile-avatar-left-code {
      font-size: rem(12px);
      color: #000403;
    }
  }

  .zh-profile-avatar-right {
    display: flex;
    align-items: center;

    .zh-avatar {
      margin-right: rem(10px);
    }

    .zh-icon {
      font-size: rem(8px);
      transform: rotate(180deg);
    }
  }
}
