@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-announcement-items {
  display: flex;

  .zh-avatar-card {
    margin-bottom: rem(25px);
  }

  @include mq($until: small-desktop) {
    display: block;
  }

  .zh-announcement-calendar {
    margin-right: rem(30px);
    margin-bottom: rem(25px);

    @include mq($until: small-desktop) {
      margin-bottom: rem(20px);
    }

    .zh-calendar {
      height: fit-content;
    }
  }

  .zh-announcement-right {
    .zh-announcement-item-title {
      font-size: rem(13px);
      margin-bottom: rem(10px);
      text-transform: uppercase;
      color: #00362d;
    }

    .zh-announcement-item-content {
      font-size: rem(14px);
      margin-bottom: rem(25px);
      line-height: rem(26px);
      color: #00362d;
    }

    .zh-announcement-item-addressed {
      .zh-announcement-item-addressed-title {
        font-size: rem(13px);
        margin-bottom: rem(10px);
        text-transform: uppercase;
        color: #00362d;
      }

      .zh-announcement-item-addressed-items {
        > *:not(:last-child) {
          margin-right: rem(15px);
        }
      }
    }
  }
}
