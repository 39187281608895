@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

// regular palette
$regularPulse: #e9e8e5;
$regularBorder: #e9e8e5;
$regularBackground: #ffffff;
$regularText: #000403;
$regularHoverBorder: #e9e8e5;
$regularHoverBackground: #f4f3f2;
$regularHoverText: #739898;

$primaryPulse: #469c9b;
$primaryBorder: #469c9b;
$primaryBackground: #469c9b;
$primaryText: #ffffff;
$primaryHoverBorder: #739898;
$primaryHoverBackground: #739898;
$primaryHoverText: #ffffff;

$invertPulse: #104d43;
$invertBorder: #104d43;
$invertBackground: #104d43;
$invertText: #ffffff;
$invertHoverBorder: #47675c;
$invertHoverBackground: #47675c;
$invertHoverText: #ffffff;

$simplePulse: #469c9b;
$simpleBorder: #469c9b;
$simpleBackground: #ffffff;
$simpleText: #104d43;
$simpleHoverBorder: #104d43;
$simpleHoverBackground: #104d43;
$simpleHoverText: #ffffff;

$dangerPulse: #fda926;
$dangerBorder: #fda926;
$dangerBackground: #ffffff;
$dangerText: #e59a23;
$dangerHoverBorder: #e59a23;
$dangerHoverBackground: #e59a23;
$dangerHoverText: #ffffff;

$dark: #010504;

// pulses
@include pulse('regularPulse', $regularPulse);
@include pulse('primaryPulse', $primaryPulse);
@include pulse('invertPulse', $invertPulse);
@include pulse('simplePulse', $simplePulse);
@include pulse('dangerPulse', $dangerPulse);

// fade
@include fadeBackground('regularFade', $regularBackground);
@include fadeBackground('primaryFade', $primaryBackground);
@include fadeBackground('invertFade', $invertBackground);
@include fadeBackground('dangerFade', $dangerPulse);

.zh-button-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: rem(4px);
  box-shadow: rem(0px 3px 4px 0px) rgba(111, 116, 126, 0.12);
  transition: 0.3s ease;

  &.has-prefix {
    .zh-button {
      padding-left: rem(30px);
    }
  }

  &.zh-button-size {
    &-small {
      .zh-button {
        padding-top: rem(5px);
        padding-bottom: rem(5px);
        font-size: rem(12px);
      }
    }
  }

  &.zh-button-type {
    &-simple {
      border: 1px solid $simpleBorder;
      background: $simpleBackground;

      .zh-button {
        color: $simpleText;

        &:hover {
          color: $simpleHoverText;
        }
      }

      .zh-button-prefix svg path {
        fill: $simpleText;
      }

      &:hover {
        border-color: $simpleHoverBorder;
        background: $simpleHoverBackground;
      }

      .zh-button-pulse {
        animation: simplePulse 1s forwards;
      }
    }

    &-danger {
      border: 1px solid $dangerBorder;
      background: $dangerBackground;

      .zh-button {
        color: $dangerText;

        &:hover {
          color: $dangerHoverText;
        }
      }

      .zh-button-prefix svg path {
        fill: $dangerText;
      }

      &:hover {
        border-color: $dangerHoverBorder;
        background: $dangerHoverBackground;
      }

      .zh-button-pulse {
        animation: dangerPulse 1s forwards;
      }
    }

    &-regular {
      border: 1px solid $regularBorder;
      background: $regularBackground;

      .zh-button {
        color: $regularText;
      }

      .zh-button-prefix svg path {
        fill: $regularText;
      }

      &:hover {
        color: $regularHoverText;
        border-color: $regularHoverBorder;
        background: $regularHoverBackground;
      }

      .zh-button-pulse {
        animation: regularPulse 1s forwards;
      }
    }

    &-primary {
      border: 1px solid $primaryBorder;
      background: $primaryBackground;

      .zh-button {
        color: $primaryText;
      }

      .zh-button-prefix svg path {
        fill: $primaryText;
      }

      &:hover {
        border-color: $invertBackground;
        background: $invertBackground;
      }

      .zh-button-pulse {
        animation: primaryPulse 1s forwards;
      }
    }

    &-invert {
      border: 1px solid $invertBorder;
      background: $invertBackground;

      .zh-button {
        color: $invertText;
      }

      .zh-button-prefix svg path {
        fill: $invertText;
      }

      &:hover {
        color: $invertHoverText;
        border-color: $invertHoverBorder;
        background: $invertHoverBackground;
      }

      .zh-button-pulse {
        animation: invertPulse 1s forwards;
      }
    }

    &-dark {
      border: 1px solid $dark;
      background: $dark;

      .zh-button {
        color: $invertText;
      }

      .zh-button-prefix svg path {
        fill: $invertText;
      }

      &:hover {
        color: $primaryHoverText;
        border-color: $primaryHoverBorder;
        background: $primaryHoverBackground;
      }

      .zh-button-pulse {
        animation: primaryPulse 1s forwards;
      }
    }

    &-disabled {
      cursor: not-allowed;
      border: 1px solid #e9e8e5 !important;
      background: #f9f9f9 !important;

      & svg {
        &,
        & path {
          fill: #b5b4b3;
        }
      }

      .zh-button {
        color: #b5b4b3 !important;
      }

      .zh-button-prefix svg path {
        fill: #b5b4b3 !important;
      }
    }

    &-transparent {
      box-shadow: none !important;
    }
  }

  .zh-button-loading {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: rem(4px);
    pointer-events: none;

    &.zh-button-loading {
      &-simple {
        animation: simpleFade 0.25s forwards;
      }

      &-danger {
        animation: dangerFade 0.25s forwards;
      }

      &-regular {
        animation: regularFade 0.25s forwards;
      }

      &-primary {
        animation: primaryFade 0.25s forwards;
      }

      &-invert {
        animation: invertFade 0.25s forwards;
      }
    }

    .zh-loader-spinner {
      position: absolute;
      left: calc(50% - 7.5px);
      top: calc(50% - 7.5px);
      transform: translate(-50%, -50%);
    }
  }

  .zh-button-pulse {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border-radius: rem(2px);
    margin: 0 !important;
    padding: 0 !important;
  }

  .zh-button-prefix {
    position: absolute;
    left: rem(10px);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  .zh-button {
    font-size: rem(13px);
    border-color: transparent;
    background: transparent;
    padding: rem(8px 10px);
    cursor: pointer;
    outline: none !important;
    @include fontMedium;
  }
}

.zh-button-group {
  display: flex;
  flex-wrap: wrap;

  > .zh-button-wrapper {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.zh-button-icon {
  width: rem(40px);
  height: rem(40px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $regularBackground;
  border-radius: rem(100px);
  border: rem(1px) solid $inputBorder;
  transition: 0.3s ease;

  &:hover {
    background: $invertBackground;
    border-color: $invertBackground;

    & svg,
    & svg path {
      fill: $regularBackground;
    }

    & svg.zh-icon-odnoklasniki path {
      fill: $invertBackground;
      stroke: $regularBackground;
    }
  }

  & svg,
  & svg path {
    fill: $invertBackground;
  }

  & svg.zh-icon-odnoklasniki path {
    fill: $regularBackground;
    stroke: $invertBackground;
  }
}
