@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-phone {
  position: relative;

  & input::placeholder {
    color: $unactiveText !important;
  }

  & .zh-input-wrapper {
    overflow: hidden;

    & .zh-input {
      padding-left: rem(110px) !important;

      .public & {
        padding-left: rem(135px) !important;
      }
    }

    & .zh-input-prefix {
      left: 0;

      & .select {
        border: none;
        & .rc-select {
          max-width: rem(100px);
          border: none;
          border-radius: 0;
          border-right: 1px solid $inputBorder;

          .public & {
            max-width: rem(110px);

            & .zh-input-phone-country {
              min-width: rem(28px);
              height: rem(28px);
            }
          }

          & .rc-select-selector {
            & .rc-select-selection-item {
              display: flex;
              align-items: center;
              top: 50% !important;
              transform: translate3d(0, -50%, 0);

              .public & {
                left: 0 !important;
              }
            }

            & input {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.zh-input-phone-country {
  width: rem(20px);
  height: rem(20px);
  margin-right: rem(10px);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: rem(100px);
}
