@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/palette';

.zh-checkbox {
  &.icon {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $inputBorder;
    height: rem(60px) !important;
    padding: 0;

    & label {
      margin-left: rem(15px) !important;
    }

    & .zh-checkbox-check-wrapper {
      display: none;

      & label {
        padding: 0;
      }
    }

    &.checked {
      border-color: #4d9c9b;
      & .prefix {
        background: #4d9c9b;
        border-color: transparent;

        & img {
          filter: invert(1);
        }
      }
    }

    & .prefix {
      width: rem(60px);
      height: rem(60px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $inputBorder;

      & img {
        width: rem(25px);
        height: rem(25px);
      }
    }

    &.checked {
      border-color: #4d9c9b;
      & .prefix {
        background: #4d9c9b;
        border-color: transparent;

        & img {
          filter: invert(1);
        }
      }
    }

    & .icon-check {
      position: absolute;
      right: rem(15px);
      top: 50%;
      transform: translate3d(0, -50%, 0);
      border-radius: rem(100px);
      overflow: hidden;
      height: rem(25px);
      width: rem(25px);

      &.checked {
        & .icon-inner {
          width: 100%;
          height: 100%;
          background: #4d9c9b;
          border-color: #4d9c9b;
          border-radius: 100%;

          &:after {
            content: '';
            filter: invert(1);
            background: url(../../../icons/checkmark.svg) no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            background-size: 65%;
            background-position: center center;
          }
        }
      }

      &:not(.checked) {
        opacity: 0;
      }
    }

    & label {
      margin-left: rem(20px);
    }

    &:before {
      width: rem(60px);
      height: rem(60px);
      border-right: 1px solid $inputBorder;
    }
  }

  & label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .zh-checkbox-mask {
      display: none;

      &:checked ~ .zh-checkbox-check-wrapper {
        background-color: #104d43;
        border-color: #104d43;
        border-radius: rem(2px);
        box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);

        .zh-icon {
          font-size: rem(9px);
        }
      }
    }

    .zh-checkbox-check-wrapper {
      position: relative;
      background-color: #f4f3f2;
      transition: background-color 0.2s;
      border: 1px solid #d4d4d4;
      border-radius: rem(2px);
      width: rem(16px);
      height: rem(16px);

      .zh-icon {
        transition: 0.4s;
        font-size: rem(0);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .zh-checkbox-label {
      padding-left: rem(8px);
    }
  }
}

.zh-checkbox-group {
  .zh-checkbox {
    &:not(:last-child) {
      margin-bottom: rem(7.5px);
    }
  }
}
