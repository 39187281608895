@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-table {
  border-bottom: 2px solid #469c9b;

  .rc-table-header,
  .rc-table-body {
    border: none;
  }

  &-row-status {
    &-pending {
      & td {
        background: rgba($warning, 0.1) !important;
      }
    }

    &-declined,
    &-deleted {
      & td {
        background: rgba($error, 0.1) !important;
      }
    }
  }

  @include mq($until: desktop) {
    display: none;
  }

  &.zh-table-size {
    &-small {
      td {
        padding: rem(10px 17.5px);
      }
    }
  }

  .rc-table-row-leftBorder {
    & td:first-child {
      border-left: 3px solid #ec9e76;
    }

    .checked .zh-icon-check path {
      border-color: #4d9c9b;
      color: #4d9c9b;
      fill: #4d9c9b;
    }
  }

  .rc-table-content {
    border: none;
  }

  .rc-table-expanded-row {
    .rc-table-cell {
      box-shadow: none;
      padding: 0;

      .flex-item {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &:last-child {
      .rc-table-cell {
        .flex-item {
          &.column {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  th,
  td {
    border-color: #e9e8e5;
    border-right: none;
    padding: rem(17.5px);

    &:first-child {
      border-left: 1px solid #e9e8e5;
    }

    &:last-child {
      border-right: 1px solid #e9e8e5;
    }
  }

  th {
    border-top: 1px solid #e9e8e5;
    word-wrap: break-word;
  }

  td {
    @include fontSemiBold;
    color: #104d43;

    a {
      color: $primary;
    }

    &.zh-time-period-cell > div {
      > .zh-info {
        display: inline-block;

        &:not(:first-child) {
          padding-left: rem(10px);

          .zh-info-icon {
            display: none;
          }
        }

        &:not(:last-child) {
          margin-right: rem(10px);
        }
      }
    }

    .zh-info {
      color: #104d43;

      path {
        fill: #698c86 !important;
      }
    }

    b,
    strong {
      @include fontSemiBold;
    }
  }

  .rc-table-thead {
    tr {
      th.rc-table-cell {
        position: relative;
        @include fontMedium;
        text-transform: uppercase;
        color: #00362d;
        text-align: left;
        background-color: #fff;
        font-size: rem(12px);
        white-space: nowrap;

        &.nowrap {
          width: rem(100px);
        }

        &.rc-table-row-expand-icon-cell + th.rc-table-cell {
          padding-left: 0;
        }

        .zh-table-th-filtered {
          cursor: pointer;
          transition: 0.2s;

          &.zh-table-th-filtered {
            &-asc,
            &-desc {
              @include fontSemiBold;
              color: #739898;

              .zh-icon-arrow-down path {
                fill: #739898;
              }
            }

            &-desc {
              .zh-icon-arrow-down {
                transform: rotate(180deg);
              }
            }
          }

          &:hover {
            color: #739898;

            .zh-icon-arrow-down path {
              fill: #739898;
            }
          }

          .zh-icon-arrow-down {
            margin-left: rem(5px);
            position: relative;
            top: rem(3px);
            transition: 0.2s;

            path {
              fill: #767676;
            }
          }
        }
      }
    }
  }

  .rc-table-tbody {
    tr {
      &.rc-table-placeholder td {
        font-size: rem(16px);
        @include fontLight;
        color: $unactiveText;
        text-align: center;
        padding: rem(25px);
      }

      &.rc-table-row {
        &:hover {
          &:nth-child(odd) td.rc-table-cell {
            background-color: rgb(240, 240, 240);
          }

          &:nth-child(even) td.rc-table-cell {
            background-color: rgb(245, 245, 245);
          }
        }

        td.rc-table-cell {
          transition: 0.2s;
          font-size: rem(12px);

          .zh-info,
          .zh-input-wrapper .zh-input {
            font-size: rem(12px);
          }

          svg {
            font-size: rem(12px);
          }

          &.rc-table-row-expand-icon-cell + td.rc-table-cell {
            padding-left: 0;
          }

          &.zh-period-cell {
            border-left: 1px solid #e9e8e5;
            border-right: 1px solid #e9e8e5;
            padding-top: 0;
            padding-bottom: 0;

            & svg {
              margin-right: rem(2.5px);
              width: rem(13px);
              height: rem(13px);

              &,
              & path {
                fill: $invertActiveBackground;
              }
            }
          }

          & > div {
            display: flex;
          }

          &.zh-period-cell > div {
            align-items: center;
          }

          & .picker-label {
            width: rem(220px);
            align-items: center;

            & .react-datepicker-wrapper {
              & input {
                background: transparent;
                border: none;
              }

              &:first-child input {
                text-align: end;
              }
            }

            & .react-datepicker-wrapper {
              margin: 0 5px;

              & .react-datepicker__input-container {
                width: auto;

                &:before {
                  background: none;
                  z-index: -1;
                }
              }

              & input {
                padding: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &:nth-child(odd) td.rc-table-cell {
          background-color: #f9f9f9;
          font-size: rem(12px);

          svg {
            font-size: rem(12px);
          }
        }

        .zh-action-wrapper {
          .zh-tooltip {
            transform: translateY(50px);
            opacity: 1 !important;
            pointer-events: inherit !important;
          }
        }
      }

      & button {
        width: 100%;
      }

      & .picker-label {
        & .react-datepicker {
          &__input-container {
            width: 142px;
          }

          &__navigation {
            width: auto;
          }

          &-wrapper {
            &:first-child input {
              border-right: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            &:not(:first-child) input {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
  }

  .expand-icon {
    transform: rotate(0);
    transition: transform 300ms;
    cursor: pointer;
    margin-top: rem(5px);

    &:hover path {
      fill: #104d43;
    }

    path {
      fill: #6b8c86;
      transition: 0.2s;
    }

    &.expanded {
      transform: rotate(90deg);

      path {
        fill: #104d43;
      }
    }
  }
}

.zh-table-mobile {
  display: none;

  .zh-button-wrapper {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.expand {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & button {
      width: 100%;
    }
  }

  & .picker-label {
    & .react-datepicker {
      &-wrapper {
        &:first-child input {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:not(:first-child) input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .expanded {
    border-top: 2px solid #e9e8e5 !important;
    background: #f9f9f9;
  }

  @include mq($until: desktop) {
    display: block;
  }

  .zh-action-wrapper {
    position: relative;

    & > div {
      margin-top: rem(20px);
    }
  }

  .zh-table-mobile-item {
    position: relative;
    border: 1px solid #e9e8e5;
    border-radius: rem(7px);
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    box-shadow: rem(0 7px 27px 3px) rgba(31, 105, 179, 0.05);

    &:not(:last-child) {
      margin-bottom: rem(15px);
    }

    .zh-info-icon {
      top: rem(-1px);
    }

    .zh-table-mobile-item-key {
      font-size: rem(16px);
      position: absolute;
      top: rem(13px);
      right: rem(15px);
      color: $unactiveText;
    }

    .zh-table-mobile-item-title {
      padding: rem(15px 15px 10px);
      font-size: rem(14px);
      width: 100%;
    }

    .zh-table-mobile-item-desc {
      padding: rem(0 15px 15px);
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #e9e8e5;
      }
    }

    .zh-table-mobile-item-child {
      padding: rem(15px);
      width: 50%;

      & > div {
        display: flex;
        align-items: center;
      }

      & .picker-label {
        align-items: center;

        & .react-datepicker-wrapper {
          & input {
            background: transparent;
            border: none;
          }
        }

        & .react-datepicker-wrapper {
          margin: 0 5px;
          width: rem(100px);

          & .react-datepicker__input-container {
            width: auto;

            &:before {
              background: none;
              z-index: -1;
            }
          }

          & input {
            padding: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &:nth-child(even) {
        border-right: 1px solid #e9e8e5;
      }

      &:last-child {
        border-right: unset;
      }

      .zh-table-mobile-item-child-title {
        display: block;
        font-size: rem(12px);
        margin-bottom: rem(5px);
        color: $unactiveText;
      }
    }

    .zh-table-mobile-item-action {
      width: 100%;
      border-top: 1px solid #469c9b;
      border-bottom-left-radius: rem(7px);
      border-bottom-right-radius: rem(7px);
      color: #fff;
      display: flex;
      align-items: center;
      text-align: center;
      padding: rem(15px 5px);

      > .flex {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      > * {
        width: 100%;
        display: block;
      }
    }

    &-child {
      &:last-child {
        width: 100%;

        & button {
          width: 100%;
        }
      }
    }

    .picker-label {
      margin: 0;
    }
  }
}
