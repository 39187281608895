@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-session-report {
  .zh-session-report-question {
    display: flex;

    @include mq($until: small-desktop) {
      display: block;
    }

    .zh-session-report-question-section {
      padding-top: rem(5px);
      width: 100%;

      &.no-label {
        padding-top: 0;
      }

      @include mq($until: small-desktop) {
        padding-top: rem(25px);
      }
    }

    .zh-session-report-question-buttons {
      > *:not(:last-child) {
        margin-right: rem(15px);
      }
    }
  }

  .zh-session-report-question-label {
    font-size: rem(15px);
  }

  .zh-form .zh-form-item .zh-form-extra-error {
    text-align: left;
  }
}
