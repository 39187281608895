@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

.zh-alert {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba($success, 0.35);
  border: 1px solid $success;
  padding: rem(10px);

  &-type-info {
    background: rgba($info, 0.35);
    border: 1px solid $info;

    & svg {
      background: $info !important;
    }
  }

  &-type-warning {
    background: rgba($warning, 0.35);
    border: 1px solid $warning;

    & svg {
      background: $warning !important;
    }
  }

  &-type-error {
    background: rgba($error, 0.35);
    border: 1px solid $error;

    & svg {
      background: $error !important;
    }
  }

  &.form {
    padding: rem(10px 30px);
  }

  & svg {
    background: $success;
    padding: rem(5px);
    width: rem(20px);
    height: rem(20px);
    border-radius: 100px;
    margin-right: rem(10px);

    &,
    & path {
      fill: #fff;
    }
  }
}
.zh-alert.zh-alert-session-table {
  font-size: rem(12px);
  line-height: rem(12px);
  svg {
    display: none;
  }
}
