@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

@keyframes fadeModal {
  0% {
    opacity: 0;
  }
  25% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zh-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 9998;

  .zh-modal {
    position: relative;
    margin: rem(150px auto 25px);
    border: 1px solid #e9e8e5;
    box-shadow: 0px 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
    border-radius: rem(4px);
    background-color: $disabledBackground;
    animation: 250ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running fadeModal;

    &.zh-modal-size {
      &-small {
        width: 100%;
        max-width: rem(550px);
      }

      &-regular {
        width: 100%;
        max-width: rem(760px);
      }

      &-large {
        width: 100%;
        max-width: rem(960px);
      }
    }

    .zh-modal-content {
      padding: rem(25px);
    }

    .zh-modal-header {
      position: relative;
      padding: rem(25px 20px);
      border-bottom: 1px solid #e9e8e5;
      background-color: $activeBackground;

      .zh-modal-title {
        font-size: rem(14px);
        @include fontMedium;
        margin: 0;
        padding: 0;
      }
    }

    .zh-modal-footer {
      padding: rem(20px);
      border-top: 1px solid #e9e8e5;
      background-color: $activeBackground;

      .zh-modal-footer-button {
        &.zh-modal-footer-button {
          &-flex {
            display: flex;
            align-items: center;

            > * {
              display: block;
              white-space: nowrap;

              > *:not(:first-child) {
                margin-left: rem(15px);
              }

              &:first-child {
                width: 100%;
              }

              &:last-child {
                width: auto;
              }
            }
          }

          &-center {
            text-align: center;

            .zh-button-wrapper:last-child {
              margin-left: rem(15px);
            }
          }
        }
      }
    }
  }
}
