@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-button-wrapper.zh-button-size-small.f10px .zh-button {
  font-size: rem(10px);
}

.zh-course-cell {
  min-width: rem(150px);
}
.zh-table .rc-table-tbody tr.rc-table-row td.rc-table-cell .picker-label {
  width: rem(145px);
}

.zh-table.zh-table-size-small td {
  padding: rem(10px 10px);
}

.download-report {
  float: right;
}
