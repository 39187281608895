@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-empty-list {
  font-size: rem(16px);
  @include fontLight;
  color: $unactiveText;
  text-align: center;
  padding: rem(50px 25px);

  &.zh-empty-list-bordered {
    border-top: 1px solid $inputBorder;
    border-bottom: 1px solid $inputBorder;
  }
}
