@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.picker-wrapper {
  display: flex;
  align-items: center;

  & > div:last-child,
  & > div:first-child:not(:last-child),
  & .picker-label > div:last-child,
  & .picker-label > div:first-child:not(:last-child) {
    margin-right: rem(10px);
  }
}

.picker-container {
  position: absolute;
  flex-direction: column-reverse;
  background: #fff;
  border: rem(1px) solid $inputBorder;
  transform: translate3d(-50%, rem(55px, 0));
  max-width: rem(600px);
  width: max-content;
  box-shadow: rem(1px 1px 10px) rgba($inputBorder, 0.5);
  z-index: 9999;

  &-date {
    transform: translate3d(0, rem(55px, 0));
  }
}

.picker-label {
  display: flex;
  width: 100%;
  margin: 0;

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & .react-datepicker__input-container:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' width='1em' height='1em' %3E%3Cg%3E%3Cg%3E%3Cpath fill='rgb(204, 204, 204)' d='M14.657 1.383c1.845 0 3.347 1.56 3.347 3.477v9.663c0 1.917-1.502 3.477-3.347 3.477H4.015C2.169 18 .668 16.44.668 14.523V4.86c0-1.918 1.501-3.477 3.347-3.477h.646v-.68A.69.69 0 0 1 5.338 0a.69.69 0 0 1 .677.703v.68h6.641v-.68A.69.69 0 0 1 13.333 0a.69.69 0 0 1 .677.703v.68zM4.015 2.789c-1.1 0-1.993.929-1.993 2.07v.672H16.65V4.86c0-1.142-.894-2.07-1.993-2.07h-.647v1.37a.69.69 0 0 1-.677.703.69.69 0 0 1-.677-.703V2.79h-6.64v1.37a.69.69 0 0 1-.678.703.69.69 0 0 1-.677-.703V2.79zm10.642 13.805c1.099 0 1.993-.93 1.993-2.07V6.936H2.022v7.586c0 1.142.894 2.07 1.993 2.07z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: rem(35px);
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
  }

  & input {
    width: 100%;
    height: rem(34.5px);
    border: 1px solid #dfddd6;
    border-radius: rem(4px);
    outline: none;
    padding-left: rem(35px);
    background: #fff;

    &.empty {
      background: #f9f9f9;
    }
  }
}

.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.react-datepicker__header__dropdown {
  select {
    border: none;
    background: transparent;
  }

  ::-webkit-scrollbar {
    width: rem(8px);
  }
}

.react-datepicker-popper {
  z-index: 999999;

  .react-datepicker__triangle {
    box-shadow: none !important;
  }

  .react-datepicker__time-list-item--disabled {
    display: none !important;
  }

  * {
    outline: 0;
  }

  .react-datepicker {
    box-shadow: rem(1px 1px 10px) rgba($inputBorder, 0.5);
    border: none;
    display: flex;

    &__navigation {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 8 12' fill='rgb(16 77 67)' class='zh-icon zh-icon-size-small zh-icon-arrow-right '%3E%3Cpath fill-rule='evenodd' d='M1.052,-0.003 C1.317,-0.003 1.581,0.098 1.783,0.300 L6.721,5.263 C7.120,5.663 7.120,6.309 6.721,6.710 L1.783,11.672 C1.382,12.074 0.731,12.077 0.328,11.677 C-0.076,11.278 -0.078,10.628 0.322,10.225 L4.541,5.986 L0.322,1.748 C-0.078,1.345 -0.076,0.695 0.328,0.295 C0.528,0.097 0.790,-0.003 1.052,-0.003 Z'%3E%3C/path%3E%3C/svg%3E");
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      width: rem(15px);
      height: rem(15px);
      border: none;

      &--previous {
        transform: rotate(180deg);
      }
    }

    &__triangle {
      border-bottom-color: #fff !important;
      box-shadow: rem(1px 1px 10px) rgba($inputBorder, 0.5);

      &:before {
        border-top-color: #fff !important;
        border-bottom-color: #fff !important;
      }
    }
    &__time-container {
      border-color: $inputBorder;
    }
    &__time ul li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__time-list-item--selected {
      background-color: $invertActiveBackground !important;
    }
    &__navigation {
      background-size: 50%;
      padding: rem(10px);
      top: rem(5px);
    }
    &__navigation--years {
      top: unset;

      &-upcoming {
        transform: rotate(-90deg);
      }

      &-previous {
        transform: rotate(90deg);
      }
    }
    &__year-option--selected {
      width: rem(20px);
      color: $primary;
      border-radius: rem(100px);
      font-size: rem(20px);
    }
    &__year-read-view--down-arrow {
      border: none;
      top: rem(7px);

      &:before {
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 8 12' fill='rgb(16 77 67)' class='zh-icon zh-icon-size-small zh-icon-arrow-right '%3E%3Cpath fill-rule='evenodd' d='M1.052,-0.003 C1.317,-0.003 1.581,0.098 1.783,0.300 L6.721,5.263 C7.120,5.663 7.120,6.309 6.721,6.710 L1.783,11.672 C1.382,12.074 0.731,12.077 0.328,11.677 C-0.076,11.278 -0.078,10.628 0.322,10.225 L4.541,5.986 L0.322,1.748 C-0.078,1.345 -0.076,0.695 0.328,0.295 C0.528,0.097 0.790,-0.003 1.052,-0.003 Z'%3E%3C/path%3E%3C/svg%3E");
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        width: rem(15px);
        height: rem(15px);
        left: auto;
        top: auto;
        bottom: auto;
        z-index: unset;
        transform: rotate(90deg) translate3d(-50%, 50%, 0);
      }
    }
    &__header {
      background: transparent;
      border: none;
    }
    &__year-read-view {
      padding: rem(5px 10px);
    }

    &__day-name {
      color: $titleText;
      font-weight: 600;
      margin: 0;
      width: rem(30px);
      height: rem(30px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &__month {
      margin: rem(0 15px 15px);
    }

    &__day {
      margin: 0;
      width: rem(30px);
      height: rem(30px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }

    &__day--selected,
    &__day--keyboard-selected {
      background-color: $primary !important;
      color: #fff !important;
    }

    &__day--selected,
    &__day--in-selecting-range,
    &__day--in-range,
    &__month-text--selected,
    &__month-text--in-selecting-range,
    &__month-text--in-range,
    &__quarter-text--selected,
    &__quarter-text--in-selecting-range,
    &__quarter-text--in-range,
    &__year-text--selected,
    &__year-text--in-selecting-range,
    &__year-text--in-range {
      background-color: $secondaryActiveBackground;
      color: #000;
    }

    &__day--range-start {
      background-color: $primary;
      color: #fff;
    }

    &__day--range-end {
      background-color: $invertActiveBackground !important;
      color: #fff;
    }

    &__day--selected:hover,
    &__day--in-selecting-range:hover,
    &__day--in-range:hover,
    &__month-text--selected:hover,
    &__month-text--in-selecting-range:hover,
    &__month-text--in-range:hover,
    &__quarter-text--selected:hover,
    &__quarter-text--in-selecting-range:hover,
    &__quarter-text--in-range:hover,
    &__year-text--selected:hover,
    &__year-text--in-selecting-range:hover,
    &__year-text--in-range:hover {
      background-color: $primaryBorder !important;
      color: #fff;
    }
  }
}
