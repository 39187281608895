@import 'styles/helpers';
@import 'styles/typography';

.zh-sort-by-wrapper {
  height: rem(36px);
  display: flex;

  &.zh-sort-desc {
    .zh-button-wrapper {
      &:first-child {
        & svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .zh-button-wrapper {
    height: rem(36px);

    & svg {
      transition: 300ms transform;
    }

    &:first-child {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.zh-sort-by {
  padding: 0 !important;
  border: 1px solid #dfe1e6;
  border-radius: rem(4px);
  width: rem(162.5px);

  .zh-sort-by-title {
    @include fontMedium;
    font-size: rem(11px);
    padding: rem(12.5px);
    border-bottom: 1px solid #e9e8e5;
    color: #3c3939;
  }

  .zh-sort-by-items {
    .zh-sort-by-item {
      position: relative;
      padding: rem(12.5px);
      font-size: rem(12px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include fontMedium;
      cursor: pointer;
      transition: 0.2s;

      &:not(:last-child) {
        border-bottom: 1px solid #e9e8e5;
      }

      &-active {
        @include fontBold;
        color: #469c9b;
      }

      &:hover {
        background-color: #f4f3f2;
        color: #739898;
      }

      .zh-icon {
        position: absolute;
        right: rem(10px);
        top: 50%;
        transform: translateY(-50%);

        path {
          fill: #469c9b;
        }
      }
    }
  }
}
