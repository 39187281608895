.iti-flag.ad {
    background-image: url("~icons/countries/ad.svg");
}

.iti-flag.ae {
    background-image: url("~icons/countries/ae.svg");
}

.iti-flag.af {
    background-image: url("~icons/countries/af.svg");
}

.iti-flag.ag {
    background-image: url("~icons/countries/ag.svg");
}

.iti-flag.ai {
    background-image: url("~icons/countries/ai.svg");
}

.iti-flag.al {
    background-image: url("~icons/countries/al.svg");
}

.iti-flag.am {
    background-image: url("~icons/countries/am.svg");
}

.iti-flag.ao {
    background-image: url("~icons/countries/ao.svg");
}

.iti-flag.aq {
    background-image: url("~icons/countries/aq.svg");
}

.iti-flag.ar {
    background-image: url("~icons/countries/ar.svg");
}

.iti-flag.as {
    background-image: url("~icons/countries/as.svg");
}

.iti-flag.at {
    background-image: url("~icons/countries/at.svg");
}

.iti-flag.au {
    background-image: url("~icons/countries/au.svg");
}

.iti-flag.aw {
    background-image: url("~icons/countries/aw.svg");
}

.iti-flag.ax {
    background-image: url("~icons/countries/ax.svg");
}

.iti-flag.az {
    background-image: url("~icons/countries/az.svg");
}

.iti-flag.ba {
    background-image: url("~icons/countries/ba.svg");
}

.iti-flag.bb {
    background-image: url("~icons/countries/bb.svg");
}

.iti-flag.bd {
    background-image: url("~icons/countries/bd.svg");
}

.iti-flag.be {
    background-image: url("~icons/countries/be.svg");
}

.iti-flag.bf {
    background-image: url("~icons/countries/bf.svg");
}

.iti-flag.bg {
    background-image: url("~icons/countries/bg.svg");
}

.iti-flag.bh {
    background-image: url("~icons/countries/bh.svg");
}

.iti-flag.bi {
    background-image: url("~icons/countries/bi.svg");
}

.iti-flag.bj {
    background-image: url("~icons/countries/bj.svg");
}

.iti-flag.bl {
    background-image: url("~icons/countries/bl.svg");
}

.iti-flag.bm {
    background-image: url("~icons/countries/bm.svg");
}

.iti-flag.bn {
    background-image: url("~icons/countries/bn.svg");
}

.iti-flag.bo {
    background-image: url("~icons/countries/bo.svg");
}

.iti-flag.bq {
    background-image: url("~icons/countries/bq.svg");
}

.iti-flag.br {
    background-image: url("~icons/countries/br.svg");
}

.iti-flag.bs {
    background-image: url("~icons/countries/bs.svg");
}

.iti-flag.bt {
    background-image: url("~icons/countries/bt.svg");
}

.iti-flag.bv {
    background-image: url("~icons/countries/bv.svg");
}

.iti-flag.bw {
    background-image: url("~icons/countries/bw.svg");
}

.iti-flag.by {
    background-image: url("~icons/countries/by.svg");
}

.iti-flag.bz {
    background-image: url("~icons/countries/bz.svg");
}

.iti-flag.ca {
    background-image: url("~icons/countries/ca.svg");
}

.iti-flag.cc {
    background-image: url("~icons/countries/cc.svg");
}

.iti-flag.cd {
    background-image: url("~icons/countries/cd.svg");
}

.iti-flag.cf {
    background-image: url("~icons/countries/cf.svg");
}

.iti-flag.cg {
    background-image: url("~icons/countries/cg.svg");
}

.iti-flag.ch {
    background-image: url("~icons/countries/ch.svg");
}

.iti-flag.ci {
    background-image: url("~icons/countries/ci.svg");
}

.iti-flag.ck {
    background-image: url("~icons/countries/ck.svg");
}

.iti-flag.cl {
    background-image: url("~icons/countries/cl.svg");
}

.iti-flag.cm {
    background-image: url("~icons/countries/cm.svg");
}

.iti-flag.cn {
    background-image: url("~icons/countries/cn.svg");
}

.iti-flag.co {
    background-image: url("~icons/countries/co.svg");
}

.iti-flag.cr {
    background-image: url("~icons/countries/cr.svg");
}

.iti-flag.cu {
    background-image: url("~icons/countries/cu.svg");
}

.iti-flag.cv {
    background-image: url("~icons/countries/cv.svg");
}

.iti-flag.cw {
    background-image: url("~icons/countries/cw.svg");
}

.iti-flag.cx {
    background-image: url("~icons/countries/cx.svg");
}

.iti-flag.cy {
    background-image: url("~icons/countries/cy.svg");
}

.iti-flag.cz {
    background-image: url("~icons/countries/cz.svg");
}

.iti-flag.de {
    background-image: url("~icons/countries/de.svg");
}

.iti-flag.dj {
    background-image: url("~icons/countries/dj.svg");
}

.iti-flag.dk {
    background-image: url("~icons/countries/dk.svg");
}

.iti-flag.dm {
    background-image: url("~icons/countries/dm.svg");
}

.iti-flag.do {
    background-image: url("~icons/countries/do.svg");
}

.iti-flag.dz {
    background-image: url("~icons/countries/dz.svg");
}

.iti-flag.ec {
    background-image: url("~icons/countries/ec.svg");
}

.iti-flag.ee {
    background-image: url("~icons/countries/ee.svg");
}

.iti-flag.eg {
    background-image: url("~icons/countries/eg.svg");
}

.iti-flag.eh {
    background-image: url("~icons/countries/eh.svg");
}

.iti-flag.er {
    background-image: url("~icons/countries/er.svg");
}

.iti-flag.es {
    background-image: url("~icons/countries/es.svg");
}

.iti-flag.et {
    background-image: url("~icons/countries/et.svg");
}

.iti-flag.fi {
    background-image: url("~icons/countries/fi.svg");
}

.iti-flag.fj {
    background-image: url("~icons/countries/fj.svg");
}

.iti-flag.fk {
    background-image: url("~icons/countries/fk.svg");
}

.iti-flag.fm {
    background-image: url("~icons/countries/fm.svg");
}

.iti-flag.fo {
    background-image: url("~icons/countries/fo.svg");
}

.iti-flag.fr {
    background-image: url("~icons/countries/fr.svg");
}

.iti-flag.ga {
    background-image: url("~icons/countries/ga.svg");
}

.iti-flag.gb {
    background-image: url("~icons/countries/gb.svg");
}

.iti-flag.gb-eng {
    background-image: url("~icons/countries/gb-eng.svg");
}

.iti-flag.gb-nir {
    background-image: url("~icons/countries/gb-nir.svg");
}

.iti-flag.gb-sct {
    background-image: url("~icons/countries/gb-sct.svg");
}

.iti-flag.gb-wls {
    background-image: url("~icons/countries/gb-wls.svg");
}

.iti-flag.gd {
    background-image: url("~icons/countries/gd.svg");
}

.iti-flag.ge {
    background-image: url("~icons/countries/ge.svg");
}

.iti-flag.gg {
    background-image: url("~icons/countries/gg.svg");
}

.iti-flag.gh {
    background-image: url("~icons/countries/gh.svg");
}

.iti-flag.gi {
    background-image: url("~icons/countries/gi.svg");
}

.iti-flag.gl {
    background-image: url("~icons/countries/gl.svg");
}

.iti-flag.gm {
    background-image: url("~icons/countries/gm.svg");
}

.iti-flag.gn {
    background-image: url("~icons/countries/gn.svg");
}

.iti-flag.gp {
    background-image: url("~icons/countries/gp.svg");
}

.iti-flag.gq {
    background-image: url("~icons/countries/gq.svg");
}

.iti-flag.gr {
    background-image: url("~icons/countries/gr.svg");
}

.iti-flag.gs {
    background-image: url("~icons/countries/gs.svg");
}

.iti-flag.gt {
    background-image: url("~icons/countries/gt.svg");
}

.iti-flag.gu {
    background-image: url("~icons/countries/gu.svg");
}

.iti-flag.gw {
    background-image: url("~icons/countries/gw.svg");
}

.iti-flag.gy {
    background-image: url("~icons/countries/gy.svg");
}

.iti-flag.hk {
    background-image: url("~icons/countries/hk.svg");
}

.iti-flag.hm {
    background-image: url("~icons/countries/hm.svg");
}

.iti-flag.hn {
    background-image: url("~icons/countries/hn.svg");
}

.iti-flag.hr {
    background-image: url("~icons/countries/hr.svg");
}

.iti-flag.ht {
    background-image: url("~icons/countries/ht.svg");
}

.iti-flag.hu {
    background-image: url("~icons/countries/hu.svg");
}

.iti-flag.id {
    background-image: url("~icons/countries/id.svg");
}

.iti-flag.ie {
    background-image: url("~icons/countries/ie.svg");
}

.iti-flag.il {
    background-image: url("~icons/countries/il.svg");
}

.iti-flag.im {
    background-image: url("~icons/countries/im.svg");
}

.iti-flag.in {
    background-image: url("~icons/countries/in.svg");
}

.iti-flag.io {
    background-image: url("~icons/countries/io.svg");
}

.iti-flag.iq {
    background-image: url("~icons/countries/iq.svg");
}

.iti-flag.ir {
    background-image: url("~icons/countries/ir.svg");
}

.iti-flag.is {
    background-image: url("~icons/countries/is.svg");
}

.iti-flag.it {
    background-image: url("~icons/countries/it.svg");
}

.iti-flag.je {
    background-image: url("~icons/countries/je.svg");
}

.iti-flag.jm {
    background-image: url("~icons/countries/jm.svg");
}

.iti-flag.jo {
    background-image: url("~icons/countries/jo.svg");
}

.iti-flag.jp {
    background-image: url("~icons/countries/jp.svg");
}

.iti-flag.ke {
    background-image: url("~icons/countries/ke.svg");
}

.iti-flag.kg {
    background-image: url("~icons/countries/kg.svg");
}

.iti-flag.kh {
    background-image: url("~icons/countries/kh.svg");
}

.iti-flag.ki {
    background-image: url("~icons/countries/ki.svg");
}

.iti-flag.km {
    background-image: url("~icons/countries/km.svg");
}

.iti-flag.kn {
    background-image: url("~icons/countries/kn.svg");
}

.iti-flag.im {
    background-image: url("~icons/countries/im.svg");
}

.iti-flag.kp {
    background-image: url("~icons/countries/kp.svg");
}

.iti-flag.kr {
    background-image: url("~icons/countries/kr.svg");
}

.iti-flag.kw {
    background-image: url("~icons/countries/kw.svg");
}

.iti-flag.ky {
    background-image: url("~icons/countries/ky.svg");
}

.iti-flag.la {
    background-image: url("~icons/countries/la.svg");
}

.iti-flag.lb {
    background-image: url("~icons/countries/lb.svg");
}

.iti-flag.lc {
    background-image: url("~icons/countries/lc.svg");
}

.iti-flag.li {
    background-image: url("~icons/countries/li.svg");
}

.iti-flag.lk {
    background-image: url("~icons/countries/lk.svg");
}

.iti-flag.lr {
    background-image: url("~icons/countries/lr.svg");
}

.iti-flag.ls {
    background-image: url("~icons/countries/ls.svg");
}

.iti-flag.lt {
    background-image: url("~icons/countries/lt.svg");
}

.iti-flag.lu {
    background-image: url("~icons/countries/lu.svg");
}

.iti-flag.lv {
    background-image: url("~icons/countries/lv.svg");
}

.iti-flag.ly {
    background-image: url("~icons/countries/ly.svg");
}

.iti-flag.ma {
    background-image: url("~icons/countries/ma.svg");
}

.iti-flag.mc {
    background-image: url("~icons/countries/mc.svg");
}

.iti-flag.md {
    background-image: url("~icons/countries/md.svg");
}

.iti-flag.me {
    background-image: url("~icons/countries/me.svg");
}

.iti-flag.mf {
    background-image: url("~icons/countries/mf.svg");
}

.iti-flag.mg {
    background-image: url("~icons/countries/mg.svg");
}

.iti-flag.mh {
    background-image: url("~icons/countries/mh.svg");
}

.iti-flag.mk {
    background-image: url("~icons/countries/mk.svg");
}

.iti-flag.ml {
    background-image: url("~icons/countries/ml.svg");
}

.iti-flag.mm {
    background-image: url("~icons/countries/mm.svg");
}

.iti-flag.mn {
    background-image: url("~icons/countries/mn.svg");
}

.iti-flag.mo {
    background-image: url("~icons/countries/mo.svg");
}

.iti-flag.mp {
    background-image: url("~icons/countries/mp.svg");
}

.iti-flag.mq {
    background-image: url("~icons/countries/mq.svg");
}

.iti-flag.ms {
    background-image: url("~icons/countries/ms.svg");
}

.iti-flag.mt {
    background-image: url("~icons/countries/mt.svg");
}

.iti-flag.mu {
    background-image: url("~icons/countries/mu.svg");
}

.iti-flag.mv {
    background-image: url("~icons/countries/mv.svg");
}

.iti-flag.mw {
    background-image: url("~icons/countries/mw.svg");
}

.iti-flag.my {
    background-image: url("~icons/countries/my.svg");
}

.iti-flag.mz {
    background-image: url("~icons/countries/mz.svg");
}

.iti-flag.na {
    background-image: url("~icons/countries/na.svg");
}

.iti-flag.nc {
    background-image: url("~icons/countries/nc.svg");
}

.iti-flag.ne {
    background-image: url("~icons/countries/ne.svg");
}

.iti-flag.nf {
    background-image: url("~icons/countries/nf.svg");
}

.iti-flag.ng {
    background-image: url("~icons/countries/ng.svg");
}

.iti-flag.ni {
    background-image: url("~icons/countries/ni.svg");
}

.iti-flag.nl {
    background-image: url("~icons/countries/nl.svg");
}

.iti-flag.no {
    background-image: url("~icons/countries/no.svg");
}

.iti-flag.np {
    background-image: url("~icons/countries/np.svg");
}

.iti-flag.nr {
    background-image: url("~icons/countries/nr.svg");
}

.iti-flag.nu {
    background-image: url("~icons/countries/nu.svg");
}

.iti-flag.nz {
    background-image: url("~icons/countries/nz.svg");
}

.iti-flag.om {
    background-image: url("~icons/countries/om.svg");
}

.iti-flag.pa {
    background-image: url("~icons/countries/pa.svg");
}

.iti-flag.pe {
    background-image: url("~icons/countries/pe.svg");
}

.iti-flag.pf {
    background-image: url("~icons/countries/pf.svg");
}

.iti-flag.pg {
    background-image: url("~icons/countries/pg.svg");
}

.iti-flag.ph {
    background-image: url("~icons/countries/ph.svg");
}

.iti-flag.pk {
    background-image: url("~icons/countries/pk.svg");
}

.iti-flag.pl {
    background-image: url("~icons/countries/pl.svg");
}

.iti-flag.pm {
    background-image: url("~icons/countries/pm.svg");
}

.iti-flag.pn {
    background-image: url("~icons/countries/pn.svg");
}

.iti-flag.pr {
    background-image: url("~icons/countries/pr.svg");
}

.iti-flag.ps {
    background-image: url("~icons/countries/ps.svg");
}

.iti-flag.pt {
    background-image: url("~icons/countries/pt.svg");
}

.iti-flag.pw {
    background-image: url("~icons/countries/pw.svg");
}

.iti-flag.py {
    background-image: url("~icons/countries/py.svg");
}

.iti-flag.qa {
    background-image: url("~icons/countries/qa.svg");
}

.iti-flag.re {
    background-image: url("~icons/countries/re.svg");
}

.iti-flag.ro {
    background-image: url("~icons/countries/ro.svg");
}

.iti-flag.rs {
    background-image: url("~icons/countries/rs.svg");
}

.iti-flag.ru {
    background-image: url("~icons/countries/ru.svg");
}

.iti-flag.rw {
    background-image: url("~icons/countries/rw.svg");
}

.iti-flag.sa {
    background-image: url("~icons/countries/sa.svg");
}

.iti-flag.sb {
    background-image: url("~icons/countries/sb.svg");
}

.iti-flag.sc {
    background-image: url("~icons/countries/sc.svg");
}

.iti-flag.sd {
    background-image: url("~icons/countries/sd.svg");
}

.iti-flag.se {
    background-image: url("~icons/countries/se.svg");
}

.iti-flag.sg {
    background-image: url("~icons/countries/sg.svg");
}

.iti-flag.sh {
    background-image: url("~icons/countries/sh.svg");
}

.iti-flag.si {
    background-image: url("~icons/countries/si.svg");
}

.iti-flag.sj {
    background-image: url("~icons/countries/sj.svg");
}

.iti-flag.sk {
    background-image: url("~icons/countries/sk.svg");
}

.iti-flag.sl {
    background-image: url("~icons/countries/sl.svg");
}

.iti-flag.sm {
    background-image: url("~icons/countries/sm.svg");
}

.iti-flag.sn {
    background-image: url("~icons/countries/sn.svg");
}

.iti-flag.so {
    background-image: url("~icons/countries/so.svg");
}

.iti-flag.sr {
    background-image: url("~icons/countries/sr.svg");
}

.iti-flag.ss {
    background-image: url("~icons/countries/ss.svg");
}

.iti-flag.st {
    background-image: url("~icons/countries/st.svg");
}

.iti-flag.sv {
    background-image: url("~icons/countries/sv.svg");
}

.iti-flag.sx {
    background-image: url("~icons/countries/sx.svg");
}

.iti-flag.sy {
    background-image: url("~icons/countries/sy.svg");
}

.iti-flag.sz {
    background-image: url("~icons/countries/sz.svg");
}

.iti-flag.tc {
    background-image: url("~icons/countries/tc.svg");
}

.iti-flag.td {
    background-image: url("~icons/countries/td.svg");
}

.iti-flag.tf {
    background-image: url("~icons/countries/tf.svg");
}

.iti-flag.tg {
    background-image: url("~icons/countries/tg.svg");
}

.iti-flag.th {
    background-image: url("~icons/countries/th.svg");
}

.iti-flag.tj {
    background-image: url("~icons/countries/tj.svg");
}

.iti-flag.tk {
    background-image: url("~icons/countries/tk.svg");
}

.iti-flag.tl {
    background-image: url("~icons/countries/tl.svg");
}

.iti-flag.tm {
    background-image: url("~icons/countries/tm.svg");
}

.iti-flag.tn {
    background-image: url("~icons/countries/tn.svg");
}

.iti-flag.to {
    background-image: url("~icons/countries/to.svg");
}

.iti-flag.tr {
    background-image: url("~icons/countries/tr.svg");
}

.iti-flag.tt {
    background-image: url("~icons/countries/tt.svg");
}

.iti-flag.tv {
    background-image: url("~icons/countries/tv.svg");
}

.iti-flag.tw {
    background-image: url("~icons/countries/tw.svg");
}

.iti-flag.tz {
    background-image: url("~icons/countries/tz.svg");
}

.iti-flag.ua {
    background-image: url("~icons/countries/ua.svg");
}

.iti-flag.ug {
    background-image: url("~icons/countries/ug.svg");
}

.iti-flag.um {
    background-image: url("~icons/countries/um.svg");
}

.iti-flag.us {
    background-image: url("~icons/countries/us.svg");
}

.iti-flag.uy {
    background-image: url("~icons/countries/uy.svg");
}

.iti-flag.uz {
    background-image: url("~icons/countries/uz.svg");
}

.iti-flag.va {
    background-image: url("~icons/countries/va.svg");
}

.iti-flag.vc {
    background-image: url("~icons/countries/vc.svg");
}

.iti-flag.ve {
    background-image: url("~icons/countries/ve.svg");
}

.iti-flag.vg {
    background-image: url("~icons/countries/vg.svg");
}

.iti-flag.vi {
    background-image: url("~icons/countries/vi.svg");
}

.iti-flag.vn {
    background-image: url("~icons/countries/vn.svg");
}

.iti-flag.vu {
    background-image: url("~icons/countries/vu.svg");
}

.iti-flag.wf {
    background-image: url("~icons/countries/wf.svg");
}

.iti-flag.ws {
    background-image: url("~icons/countries/ws.svg");
}

.iti-flag.xk {
    background-image: url("~icons/countries/xk.svg");
}

.iti-flag.ye {
    background-image: url("~icons/countries/ye.svg");
}

.iti-flag.yt {
    background-image: url("~icons/countries/yt.svg");
}

.iti-flag.za {
    background-image: url("~icons/countries/za.svg");
}

.iti-flag.zm {
    background-image: url("~icons/countries/zm.svg");
}

.iti-flag.zw {
    background-image: url("~icons/countries/zw.svg");
}