@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-roadmap-wrapper {
  padding-bottom: rem(35px);
  border-bottom: 1px solid $inputBorder;

  .zh-roadmap {
    padding-top: rem(35px);
    display: flex;
    position: relative;

    @include mq($until: small-desktop) {
      display: block;
    }

    &:first-child {
      border-top: 1px solid #dfe1e6;
    }

    &:not(:first-child) {
      border-top-color: #e9e8e5;
    }

    .zh-roadmap-left {
      position: relative;
      min-width: rem(200px);

      @include mq($until: small-desktop) {
        width: 100%;
        margin-bottom: rem(25px);
      }

      &:before {
        content: ' ';
        position: absolute;
        left: calc(50% - 12.5px);
        top: rem(-35px);
        transform: translateX(-50%);
        width: rem(1px);
        height: 100%;
        padding-bottom: rem(70px);
        background-color: #dfddd6;

        @include mq($until: small-desktop) {
          display: none;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        right: -50%;
        top: rem(67.5px);
        width: 100%;
        height: rem(1px);
        background-color: #dfddd6;

        @include mq($until: small-desktop) {
          display: none;
        }
      }

      .zh-roadmap-dot {
        display: block;
        position: absolute;
        left: calc(50% - 12.5px);
        top: rem(55px);
        transform: translateX(-50%);
        background-color: #fff;
        width: rem(26px);
        height: rem(26px);
        z-index: 1;

        @include mq($until: small-desktop) {
          display: none;
        }

        &:after {
          content: ' ';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: rem(8px);
          height: rem(8px);
          background-color: #ffffff;
          border: rem(4px) solid #469c9b;
          border-radius: 50%;
        }
      }

      .zh-roadmap-target {
        text-transform: capitalize;
        padding: rem(7.5px);
        text-align: center;
        width: calc(100% - 40px);
        border: 1px solid #dfddd6;
        border-radius: rem(4px);
        @include fontSemiBold;
        font-size: rem(14px);
        color: #000403;
        margin-right: auto;
        background-color: #fff;
        position: relative;
        z-index: 1;

        @include mq($until: small-desktop) {
          width: auto;
          margin: rem(0 25px);

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: rem(1px);
            height: 100%;
            background-color: #dfddd6;
          }
        }
      }
    }

    .zh-roadmap-right-wrapper {
      width: 100%;

      .zh-roadmap-left {
        position: absolute;
        left: 0;

        &:before {
          display: none;
        }
      }

      .zh-roadmap-right {
        position: relative;
        width: 100%;
        border: 1px solid #e9e8e5;
        border-bottom: 2px solid #469c9b;
        min-height: rem(125px);
        background-color: #fff;
        z-index: 2;

        & .zh-icon-close {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 36px;
          height: 36px;
          cursor: pointer;
          padding: 10px;
          border-radius: rem(100px);
          transition: 0.2s;

          &:hover {
            background-color: #f4f3f2;
          }

          &,
          & path {
            fill: #e64246;
          }
        }

        &:not(:last-child) {
          margin-bottom: rem(25px);
        }

        > * {
          padding: rem(30px 25px 0);
        }
      }
    }
  }
}
