@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-calendar {
  background: #fff;
  border: 1px solid #d1e0e0;
  border-radius: rem(4px);

  // keep it in pixels
  width: rem(55px);

  @include mq($until: small-desktop) {
    display: flex;
    align-items: center;
    width: rem(120px);
  }

  &.zh-calendar-type {
    // &-date {
    // }

    // &-date-time {
    // }

    &-time {
      .zh-calendar-time {
        border-radius: rem(4px);
      }
    }
  }

  .zh-calendar-date {
    text-align: center;
    padding: rem(10px 0);

    @include mq($until: small-desktop) {
      width: 100%;
      display: flex;
      align-items: center;
      padding: rem(0 10px);
    }

    .zh-calendar-month {
      display: block;
      @include fontMedium;
      font-size: rem(13px);
      color: #3c3939;
      margin-bottom: rem(5px);

      @include mq($until: small-desktop) {
        margin-right: rem(5px);
      }
    }

    .zh-calendar-day {
      display: block;
      @include fontSemiBold;
      color: #000403;
      font-size: rem(20px);

      @include mq($until: small-desktop) {
        font-size: rem(16px);
      }
    }
  }

  .zh-calendar-time {
    text-align: center;
    @include fontMedium;
    background: #469c9b;
    margin: rem(-1px);
    color: #ffffff;
    font-size: rem(13px);
    border-bottom-left-radius: rem(4px);
    border-bottom-right-radius: rem(4px);
    padding: rem(10px 0);

    @include mq($until: small-desktop) {
      border-top-right-radius: rem(4px);
      border-bottom-left-radius: 0;
      padding: rem(10px);
    }
  }
}
