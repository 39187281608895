@import 'styles/palette';
@import 'styles/helpers';

.content {
  width: 100%;
  max-width: rem(1200px);
  margin: 0 auto;
}

.container {
  background: #fff;
  padding: rem(20px);
  margin-top: -(rem(100px));
  margin-bottom: rem(40px);
  border: rem(1px) solid $inputBorder;

  & h3 {
    font-size: rem(26px);
    font-weight: 400;
    white-space: nowrap;
    margin: rem(10px 0);
  }
  & hr {
    margin: rem(40px 0 20px);
  }

  & .rc-table {
    margin: rem(0 20px);
  }
}

.mini {
  max-width: rem(460px);
}
