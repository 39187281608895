@import 'styles/palette';

.m-courses-select {
  .drag-select-item {
    transition: transform 0.2s;
    white-space: nowrap;
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid $regularBorder;
    background: #fff;
    border-radius: 3px;
    margin-left: 1px;
    margin-bottom: 10px;
  }

  .drag-select-item:hover {
    transform: scale(1.1);
  }

  .drag-select-item.drag-active {
    border-right: 3px solid green;
  }
}
