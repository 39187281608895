@import 'styles/palette';
@import 'styles/helpers';

.auth__block {
  display: flex;
  flex-direction: column;
  padding: rem(20px);

  & .zh-form-item .zh-form-children {
    position: relative;

    & > *:not(:last-child) {
      margin-bottom: rem(20px);
    }
  }

  & > *:not(:last-child) {
    margin-bottom: rem(20px);
  }

  & h3 {
    font-size: rem(26px);
    margin: 0 auto !important;
    padding-bottom: rem(40px);
    font-weight: 500;
    white-space: normal;
  }

  &--options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .useEmail {
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: 0;
    color: $unactiveText;
    font-size: 12px;

    &:hover {
      color: $primary;
    }
  }
}
