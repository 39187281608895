@import 'styles/helpers';
@import 'styles/typography';

.zh-create-announcement-content {
  padding: rem(15px 50px) !important;
  text-align: inherit;

  .zh-create-announcement-items {
    display: flex;

    > * {
      width: 100%;

      &:first-child {
        width: 45%;
        margin-right: 5%;
      }
    }
  }

  .zh-create-announcement-multitool {
    display: flex;
    flex-wrap: wrap;

    .select {
      width: calc(100% - 12.82745rem - 1.04006rem);
    }

    .zh-form-extra-error {
      width: 100%;
      text-align: left;
    }

    .zh-create-announcement-select-all {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: rem(15px);
      width: rem(185px);
      height: rem(32.5px);
      border: 1px solid #dfe1e6;
      border-radius: rem(4px);
      background-color: #fff;
      color: #00362d;
      @include fontMedium;
      padding-left: rem(34px);
      padding-right: rem(25px);
      white-space: nowrap;
      cursor: pointer;
      transition: 0.2s;

      &:after {
        content: ' ';
        position: absolute;
        left: rem(10px);
        top: 50%;
        transform: translateY(-50%);
        background-color: #f4f3f2;
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        border-radius: rem(2px);
        width: rem(16px);
        height: rem(16px);
        transition: 0.2s;
      }

      &:hover {
        border-color: #dfe1e6;
        color: #739898;

        &:after {
          border-color: #739898;
        }
      }

      &.active {
        background-color: #104d43;
        border-color: #104d43;
        color: #fff;
        box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);

        .zh-icon {
          opacity: 1;
        }

        &:after {
          border-color: #fff;
          background-color: #fff;
        }
      }

      .zh-icon {
        position: absolute;
        left: rem(14px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-size: rem(10px);
        transition: 0.2s;
        opacity: 0;

        path {
          fill: #00362d;
        }
      }
    }
  }

  .zh-checkbox {
    white-space: nowrap;
  }
}
