@import 'styles/helpers';
@import 'styles/typography';

.zh-fragment {
  &.zh-fragment-type {
    &-grey {
      border: 1px solid #e9e8e5;

      .zh-fragment-content {
        background-color: #f9f9f9;
      }
    }

    &-white {
      border: 1px solid #e9e8e5;
    }
  }

  .zh-fragment-content {
    padding: rem(30px);
  }

  .zh-fragment-footer {
    padding: rem(20px);
    text-align: center;
    border-top: 1px solid #e9e8e5;

    > *:not(:first-child) {
      margin-left: rem(10px);
    }
  }
}
