// globals
$primary: #469c9b;
$unactive: #dfddd6;
$unactive2: #ccc;

// texts
$regularText: #000403;
$invertRegularText: #fff;
$labelText: #00362d;
$titleText: #104d43;

$unactiveText: #888888;

// background
$activeBackground: #fff;
$invertActiveBackground: #104d43;
$secondaryActiveBackground: #d1e0e0;

$unactiveBackground: #f4f3f2;

$disabledBackground: #f9f9f9;

$secondaryBackground: #dfe1e6;
$secondaryBackgroundHalf: rgba(224, 225, 230, 0.5);

$inputBackground: #f9f9f9;
$inputBorder: #dfddd6;

// borders
$regularBorder: #dfe1e6;
$primaryBorder: #739898;
$secondaryBorder: #d4d4d4;

//others
$success: #8cc62f;
$info: #3f79ff;
$warning: #ffc638;
$error: #ff423f;
