@import 'styles/helpers';

@include fadeExactBackground('fadeMask', rgba(0, 0, 0, 0.4));

.zh-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  animation: fadeMask 500ms forwards;
  z-index: 9997;
}
