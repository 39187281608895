@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.applications {
  .flex-data {
    display: flex;
    flex-direction: column;
    border: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;
  }

  .flex-item {
    display: flex;
    justify-content: space-between;
    padding: rem(25px 25px 0);
    border-bottom: 1px solid $inputBorder;

    & svg,
    & path {
      fill: $primary;
    }

    &-map {
      padding: 0;
      border: none;
    }

    &-row {
      width: 100%;
      padding-right: rem(20px);

      &-col {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        .pointer {
          font-size: rem(14px);
          color: $titleText;
        }

        &.card {
          background: #f1f1f1;
          padding: rem(15px 15px 0);
          margin-bottom: rem(15px);
          border-left: 3px solid $primary;

          & > div {
            flex: 0 1 50%;
          }

          .card-title {
            font-size: rem(16px);
          }

          .card-details {
            margin-top: 0;
            flex: 0 0 calc(100% + 30px - 3px) !important;
            padding: rem(15px 15px 0);
            margin: rem(0 0 3px -15px);
            background: #fff;
          }

          .zh-avatar-inline-role {
            text-transform: unset;
          }
        }

        &.details {
          margin-bottom: 0;

          & > div {
            flex: 0 1 calc(100% / 3);
          }

          & .zh-icon-arrow-right {
            margin: 0 rem(5px);
            padding-top: rem(5px);

            &,
            & path {
              fill: $unactive2;
            }
          }

          label {
            height: rem(30px);
            display: flex;
            align-items: center;
          }
        }

        &.column {
          flex-direction: column;

          > div {
            margin-bottom: 0;
          }

          > label {
            font-size: rem(14px);
            color: gray;
            line-height: rem(20px);
            margin-top: rem(15px);
          }
        }

        > div > label {
          display: flex;
          font-size: rem(13px);
          color: $labelText;
          text-transform: uppercase;
          margin-bottom: rem(10px);
        }
      }

      & .zh-info {
        font-size: rem(14px);

        &:first-child {
          font-size: rem(16px);
        }
      }
    }

    &-actions {
      margin-top: 0 !important;
      white-space: nowrap;

      & > div {
        &:not(:last-child) {
          margin-right: rem(15px);
        }
      }
    }

    @include mq($until: desktop) {
      position: relative;

      &-map &-row {
        &-col {
          flex-wrap: wrap;
        }
      }

      &-row {
        &-col {
          &.details {
            & > div {
              flex: 0 0 50% !important;
              margin-top: 0 !important;

              &:not(:nth-last-child(2)):not(:last-child) {
                margin-bottom: rem(15px);
              }
            }
          }
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;

      &-map &-actions {
        margin-top: 0;
      }
      &-actions {
        margin-top: rem(25px);
        display: flex;
        justify-content: center;
      }

      &-row {
        &-col {
          & > div {
            flex: 0 0 50%;

            &:nth-last-child(2) {
              padding-right: 0;
            }

            &:last-child {
              flex: 0 0 100%;
              margin-top: rem(25px);
            }
          }
        }
      }
    }
  }

  .archive {
    margin-left: 15px;
  }
  .modal-form-box {
    display: flex;
  }
}
