@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-phone {
  &.error {
    .intl-tel-input input,
    .intl-tel-input input:focus {
      border-color: $error;
    }
  }

  .intl-tel-input {
    width: 100%;

    .country-list {
      width: 100%;
      overflow-x: hidden;
      z-index: 9;

      .flag-box {
        width: 28px;
      }
    }

    .selected-flag {
      width: 70px;
      outline: none;
    }

    &.allow-dropdown {
      input {
        padding-left: 80px;
      }

      .iti-flag {
        background-position: center;
        background-size: cover;
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
    }

    input {
      width: 100%;
      height: rem(32.5px);
      border-radius: rem(4px);
      border: 1px solid $unactive;
      background-color: $activeBackground;

      &:focus {
        outline: none;
        border-color: $primaryBorder;
      }
    }
  }
}
