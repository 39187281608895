@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-media-group {
  display: flex;

  .zh-media {
    position: relative;
    width: rem(32.5px);
    height: rem(32.5px);
    border: 1px solid #d2e1e1;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;

    &:not(:last-child) {
      margin-right: rem(10px);
    }

    &:hover {
      background: $invertActiveBackground;
      border-color: $invertActiveBackground;

      & .zh-icon {
        &,
        & path {
          fill: $invertRegularText;
        }

        &.zh-icon-odnoklasniki path {
          fill: $invertActiveBackground;
          stroke: $invertRegularText;
        }
      }
    }

    .zh-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.zh-icon-odnoklasniki path {
        fill: $invertRegularText;
        stroke: $invertActiveBackground;
      }

      path {
        fill: #00362d;
      }
    }
  }
}
