@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.searchbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: rem(20px);

  > *:not(:last-child) {
    border-right: inherit !important;
  }

  .zh-button {
    font-size: 17px !important;
  }

  .zh-input-wrapper {
    height: rem(60px);

    &.zh-input-wrapper-active {
      border-color: #dfddd6;
    }

    & input {
      font-size: rem(17px);
      height: 100% !important;
      margin: 0 !important;
    }
  }

  .select {
    height: rem(60px);
    border-radius: 0;
    display: grid;

    & .rc-select {
      margin-top: rem(-5px);

      & .rc-select-selection-placeholder {
        & .zh-loader-inline {
          color: #000403;

          & .zh-loader-spinner {
            left: rem(20px);
          }
        }
      }
    }

    &.select-notlabeled .zh-select-close {
      top: calc(50%) !important;
    }

    .zh-select-close {
      // 10px
      top: calc(50% + 0.69338rem);
      right: rem(30px);
    }
  }

  & .zh-icon {
    font-size: rem(24px);
  }

  & > div {
    flex: 0 1 calc(70% / 4);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-collapse: collapse;
    margin: 0;

    & label,
    & .rc-select-selection-placeholder,
    & .rc-select-arrow {
      padding-left: rem(20px) !important;
      padding-right: rem(20px) !important;
    }

    &.zh-input-wrapper {
      background: #fff;
    }

    & .rc-select {
      &-selection-item,
      &-selection-placeholder {
        display: flex;
        align-items: center;
        left: 0 !important;
        padding: rem(0 20px);
      }
    }

    &:nth-last-child(2) {
      & .rc-select {
        margin-top: 0;
      }
      & .rc-select-selection-placeholder {
        color: $unactiveText;
      }
    }

    &:not(:nth-last-child(2)):not(:first-child) input {
      margin-bottom: rem(7.38px) !important;
    }

    &:first-child {
      flex: 0 1 30%;

      & input {
        padding-left: rem(65px) !important;

        &::placeholder {
          color: $unactiveText;
        }
      }

      & .zh-input-prefix {
        left: rem(20px);
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .searchbar {
    & > div {
      flex: 0 1 calc(100% / 4);

      &:first-child {
        flex: 0 1 100%;
        border-bottom: 0;
        border-right: 1px solid #dfddd6 !important;
      }
    }
  }
}

@media screen and (max-width: 686px) {
  .searchbar {
    & > div {
      flex: 0 1 calc(100% / 3);

      &:first-child,
      &:last-child {
        flex: 0 1 100%;
      }

      &:nth-child(4) {
        border-right: 1px solid #dfddd6 !important;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .searchbar {
    & > div {
      flex: 0 1 calc(100% / 2);

      &:first-child,
      &:last-child,
      &:nth-last-child(2) {
        flex: 0 1 100%;
      }

      &:nth-child(3) {
        border-right: 1px solid #dfddd6 !important;
      }
    }
  }
}
