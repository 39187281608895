@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/media-queries';

.zh-session-title {
  display: flex;
  align-items: center;
  font-size: rem(16px);
  @include fontSemiBold;
  margin-bottom: rem(5px);

  & .zh-status {
    margin-left: rem(5px);
  }
}

.zh-session-subtitle {
  font-size: rem(14px);
  @include fontRegular;
  margin-bottom: rem(0);
}

.zh-session-card {
  border: 1px solid #e9e8e5;
  border-top: 3px solid #469c9b;

  .zh-session-card-item {
    padding: rem(30px);

    &:first-child {
      display: flex;

      @include mq($until: desktop) {
        display: block;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #e9e8e5;
    }

    .zh-session-card-item-title {
      font-size: rem(16px);
      margin-bottom: rem(20px);
      @include fontMedium;
    }

    .zh-session-card-item-faculties {
      padding-left: 0;
      margin: 0;

      li {
        padding-left: rem(10px);
        position: relative;
        list-style: none;
        font-size: rem(14px);

        &:not(:last-child) {
          margin-bottom: rem(10px);
        }

        &:after {
          content: ' ';
          display: block;
          background: #469c9b;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          // keep it in pixels
          width: (4px);
          height: (4px);
        }
      }
    }

    .zh-session-card-info {
      width: 100%;

      &:not(:last-child) {
        margin-right: rem(25px);

        @include mq($until: desktop) {
          margin-right: 0;
          margin-bottom: rem(25px);
        }
      }

      .zh-session-card-info-label {
        max-width: rem(260px);
        font-size: rem(13px);
        text-transform: uppercase;
        color: #00362d;
        margin-bottom: rem(5px);

        @include mq($until: desktop) {
          max-width: 100%;
        }
      }

      .zh-session-card-info-text {
        max-width: rem(260px);
        color: #000403;
        font-size: rem(14px);
        @include fontMedium;

        @include mq($until: desktop) {
          max-width: 100%;
        }

        .zh-info {
          @include fontMedium;
          font-size: rem(14px);

          .zh-icon {
            font-size: rem(16px);

            path {
              fill: #469c9b;
            }
          }
        }
      }
    }
  }
}

.zh-session-contacts {
  display: flex;

  @include mq($until: wide) {
    display: block;
  }

  .zh-session-contact-item {
    width: 100%;
    border: 1px solid #dfe1e6;
    border-bottom: 3px solid #469c9b;
    background-color: #f9f9f9;
    padding: rem(30px 25px);

    @include mq($until: wide) {
      margin-left: 0 !important;
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-bottom: rem(25px);
      }
    }

    &:not(:first-child) {
      margin-left: rem(15px);
    }

    &:not(:last-child) {
      margin-right: rem(15px);
    }

    h3 {
      @include fontMedium;
      font-size: rem(16px);
      margin-bottom: rem(20px);
    }

    .zh-session-contacts {
      display: flex;

      @include mq($until: tablet) {
        display: block;
      }

      .zh-session-contact-card {
        width: 100%;

        @include mq($until: tablet) {
          margin-left: 0 !important;
          margin-right: 0 !important;

          &:not(:last-child) {
            margin-bottom: rem(25px);
          }
        }

        &:not(:first-child) {
          margin-left: rem(12.5px);
        }

        &:not(:last-child) {
          margin-right: rem(12.5px);
        }
      }
    }
  }
}
