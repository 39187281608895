@import 'styles/helpers';
@import 'styles/typography';

.zh-session-contact-card {
  background-color: #fff;
  padding: rem(20px);
  border: 1px solid #dfddd6;
  border-radius: (4px);

  .zh-avatar-inline {
    margin-bottom: rem(25px);

    .zh-session-contact-card-name {
      display: block;
      margin-bottom: rem(2.5px);
      color: initial;
    }

    .zh-session-contact-card-desc {
      display: block;
      color: #104d43;
      font-size: rem(11px);
    }
  }

  .zh-info {
    margin-top: rem(12.5px);

    .zh-info-icon {
      top: 0;

      .zh-icon {
        font-size: rem(14px);

        path {
          fill: #00362d;
        }
      }
    }
  }

  .zh-session-contact-card-medias {
    margin-top: rem(25px);
  }
}
