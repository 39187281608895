@import 'styles/helpers';
@import 'styles/typography';

// may be separated in an organism
.zh-upload {
  display: flex;
  position: relative;

  & > .zh-icon-file {
    width: rem(60px);
    height: rem(60px);
    padding: rem(15px);
    background: #fc9570;
    border-radius: rem(4px);

    &,
    & path {
      fill: #fff;
    }
  }

  .zh-upload-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .zh-upload-info {
    padding-left: rem(15px);

    .zh-upload-title {
      @include fontSemiBold;
      color: #000403;
      font-size: rem(15px);
      margin-bottom: rem(12.5px);
    }

    .zh-upload-desc {
      font-size: rem(13px);
      margin-bottom: rem(12.5px);
      max-width: rem(455px);
    }
  }
}
