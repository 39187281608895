@import 'styles/helpers';
@import 'styles/media-queries';
@import 'styles/typography';

#invite-student {
  max-width: rem(805px);
  margin: rem(15px auto 10px);

  .zh-invite-student-condition {
    margin-top: rem(27.5px);
    margin-left: rem(20px);
    margin-right: rem(15px);
    width: rem(70px);
    text-align: center;

    @include mq($until: small-desktop) {
      margin: rem(15px 0);
    }
  }
}

.invite-student-select {
  .rc-select-selector {
    max-height: rem(82.5px);
    overflow-y: auto;
    @include hiddenScroll;
  }
}

.invite-student-tag {
  .invite-student-tag-title {
    @include fontMedium;
  }

  .invite-student-tag-email {
    text-transform: lowercase;
    color: #777;
    font-size: rem(10px);
  }
}
