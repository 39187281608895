@import 'styles/helpers';
@import 'styles/typography';

.rc-tooltip {
  opacity: 1 !important;
  pointer-events: all !important;
}

.zh-tooltip {
  background: transparent;

  &.zh-tooltip {
    &-fixed {
      position: fixed;
    }

    &-relative {
      position: absolute;
    }
  }

  &.rc-tooltip-placement {
    &-bottomLeft .rc-tooltip-arrow {
      left: rem(15px);
    }

    &-bottomRight .rc-tooltip-arrow {
      right: rem(15px);
    }

    &-right .rc-tooltip-arrow,
    &-rightTop .rc-tooltip-arrow,
    &-rightBottom .rc-tooltip-arrow {
      border-right-color: #fff;
    }

    &-bottom .rc-tooltip-arrow,
    &-bottomLeft .rc-tooltip-arrow,
    &-bottomRight .rc-tooltip-arrow {
      border-bottom-color: #fff;
    }

    &-left .rc-tooltip-arrow,
    &-leftTop .rc-tooltip-arrow,
    &-leftBottom .rc-tooltip-arrow {
      border-left-color: #fff;
    }

    &-top .rc-tooltip-arrow,
    &-topRight .rc-tooltip-arrow,
    &-topLeft .rc-tooltip-arrow {
      border-top-color: #fff;
    }
  }

  .rc-tooltip-arrow {
    box-shadow: 0px 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
    background: transparent;
  }

  .rc-tooltip-inner {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
    border: inherit;
    padding: 0;
  }

  .zh-tooltip-content {
    padding: rem(8px 10px);
  }
}
