@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/palette';

.zh-notifications-bell {
  position: relative;
  padding: rem(10px 12px);
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s;
  display: inline-block;

  &:hover {
    background-color: #f4f3f2;
  }

  &.disabled {
    cursor: not-allowed;

    .zh-icon path {
      fill: $unactiveText;
    }
  }

  .zh-icon {
    font-size: rem(19px) !important;
  }

  .zh-notifications-bell-badge {
    position: absolute;
    top: rem(0px);
    right: rem(-2.5px);
    background-color: #e83f47;
    color: #fff;
    border-radius: 50%;
    @include fontMedium;
    padding: rem(2.5px 6px);
    font-size: rem(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(18px);
    height: rem(18px);
  }
}

.zh-notifications {
  width: rem(365px);
  padding: 0 !important;

  .zh-notifications-title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e8e5;
    padding: rem(15px);
    user-select: none;

    span {
      font-size: rem(13px);
      display: block;
    }

    span:first-child {
      color: #3c3939;
      @include fontSemiBold;
      width: 100%;
      display: block;
    }

    span:last-child {
      width: auto;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: #739898;
      }
    }
  }

  .zh-notifications-items {
    .zh-notifications-item {
      position: relative;
      padding: rem(15px);
      padding-left: rem(50px);
      background-color: #fff;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        background-color: #f4f3f2;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e9e8e5;
      }

      > .zh-icon {
        position: absolute;
        top: rem(15px);
        left: rem(25px);
        font-size: rem(16px);
      }

      .zh-notifications-item-title {
        @include fontSemiBold;
        font-size: rem(13px);
        color: #104d43;
        margin-bottom: rem(5px);
      }

      .zh-notifications-item-info {
        font-size: rem(12px);
        color: #767676;
      }
    }
  }
}
