@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.outstandings {
  width: 100%;

  .flex-data {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $inputBorder;
    border-right: 1px solid $inputBorder;
    border-bottom: 2px solid $primary;
    width: inherit;

    .flex-item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: rem(25px);
      width: 100%;
      border-bottom: 1px solid $inputBorder;
      border-left: 3px solid $warning;

      &-row {
        display: flex;
        flex-direction: row;
        width: 75%;
        justify-content: space-between;
        padding-right: 0;

        &-item {
          display: flex;
          flex-direction: column;
          margin-bottom: rem(10px);
          width: 25%;

          > * {
            margin-bottom: rem(5px) !important;
            font-size: rem(16px);
          }

          > label {
            font-size: rem(13px);
            color: $labelText;
            line-height: rem(20px);
            font-weight: 400;
          }

          > p {
            color: grey;
            font-size: rem(14px);
            font-weight: 400;
          }
        }
      }
    }
  }
}
