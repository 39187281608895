@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

.zh-confirmation-content {
  text-align: center;

  h2 {
    @include fontSemiBold;
    font-size: rem(16px);
    margin: rem(15px 0 10px);
  }

  p {
    margin: 0;
    color: #333333;
    @include fontRegular;
    font-size: rem(13px);
  }

  & .expanded {
    text-align: left;

    & .flex-item {
      border: none;
      padding: 20px 0 0;
      margin-top: 20px;
      border-top: 1px solid $inputBorder;
    }
  }
}
