@import 'styles/palette';
@import 'styles/helpers';

.zh-rich-text-wrapper {
  border: 1px solid $unactive;
  border-radius: rem(4px);

  .zh-rich-text-toobar {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $unactive;
    margin-bottom: 0;
    font-size: rem(11px);

    .rdw-option-wrapper {
      border: none;
      box-shadow: none !important;

      &[title='Superscript'] {
        position: relative;
        top: rem(-3px);
      }
    }

    .rdw-dropdown-wrapper {
      border: rem(1px) solid $unactive;
      border-radius: rem(4px);
    }
  }

  .zh-rich-text {
    padding: rem(15px);
    background: #fff;

    min-height: rem(105px);
  }
}
