@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.zh-place-cell {
  min-width: rem(150px);
}

.zh-course-cell {
  min-width: rem(150px);
}
