@import 'styles/helpers';
@import 'styles/typography';

.zh-status {
  display: inline-block;
  border-radius: rem(4px);
  padding: rem(2.5px 5px);
  font-size: rem(11px);
  @include fontMedium;
  color: #fff;
  width: fit-content;

  &.zh-status-type {
    &-active {
      background: #469c9b;
    }

    &-regular {
      background: #fc9570;
    }

    &-declined {
      background: #000;
    }

    &-uanctive {
      background: #767676;
    }
  }

  &.zh-status-max-width {
    .zh-status-span-max-width {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }

    &::after {
      content: attr(data-label);
      z-index: 9;
      position: absolute;
      text-align: center;
      width: auto;
      padding: 5px;
      background-color: #fff;
      border-radius: 4px;
      color: #000000;
      top: -20px;
      box-shadow: 4px 4px 6px 0 rgba(151, 152, 153, 0.5);
      display: none;
    }

    &::before {
      z-index: 10;
      content: ' ';
      position: absolute;
      border: 5px solid #fff;
      top: 4px;
      border-bottom-color: transparent;
      border-right-color: transparent;
      display: none;
    }

    &:hover::after,
    &:hover::before {
      display: block;
    }
  }
}

.zh-table .rc-table-tbody tr.rc-table-row td.rc-table-cell .zh-status-span-max-width {
  display: block;
}
