@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/media-queries';

.register__block,
.auth__block {
  display: flex;
  flex-direction: column;
  padding: rem(20px);

  & input {
    display: flex;
    align-items: center;
    height: rem(60px) !important;
    padding: rem(0 20px);
  }

  @include mq($until: 768px) {
    & .zh-form-items,
    & .zh-form-children {
      max-width: 100%;
    }
  }

  & .rc-select-selection-placeholder {
    padding: rem(0 10px);
  }

  &.zh-form {
    & .zh-form-items .zh-form-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      & h3 {
        font-size: rem(20px);
        padding-top: rem(10px);
        padding-bottom: rem(20px);
        font-weight: 500;
        margin: 0 !important;
      }

      & > div {
        width: 100%;

        @include mq($until: 768px) {
          padding-bottom: rem(5px);
        }
      }

      & .zh-form-extra {
        margin-bottom: rem(-22px);
        padding: rem(2px);

        @include mq($until: 768px) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &:not(:first-child) {
        margin-left: rem(20px);
      }

      @include mq($until: 768px) {
        margin-left: 0 !important;
      }
    }

    & .zh-form-items:first-child h3 {
      padding-top: 0;
    }
  }

  & .zh-input-wrapper .zh-input-prefix.clickable,
  .zh-input-wrapper .zh-input-suffix.clickable {
    padding: rem(10px);
  }

  & .rc-select-selection-placeholder,
  & .rc-select-arrow,
  & .rc-select-selection-item,
  & .zh-input-container {
    padding: rem(0 10px);
  }

  &.rc-select {
    padding: 0;

    & .rc-select-arrow {
      right: rem(20px);
    }
    & .rc-select-selection-placeholder {
      padding: 0 rem(5px);
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: rem(20px 20px 0);

  & .zh-button-wrapper:first-child {
    margin-left: rem(-20px);
  }

  &.border {
    border-top: 1px solid $inputBorder;
    padding: rem(40px 0 0);
  }

  & .zh-button-wrapper:last-child .zh-button {
    height: rem(60px);
    padding: rem(0 80px);
  }
}
