@import 'styles/helpers';
@import 'styles/palette';
@import 'styles/typography';

.zh-select-user-tooltip {
  padding: 0 !important;
  border: 1px solid #e9e8e5;

  .zh-select-user-tooltip-title {
    padding: 0;
    height: rem(34px);
    border-bottom: 1px solid #e9e8e5;
    background-color: #f9f9f9;

    .zh-icon path {
      fill: #00362d;
    }
  }

  .zh-checkbox-group {
    .zh-checkbox {
      margin: 0;

      label {
        display: flex;
        padding: rem(10px);
      }
    }

    &-content {
      display: flex;
      position: relative;

      &.recommended {
        background: rgba($warning, 0.1);
      }

      .zh-checkbox {
        width: 100%;
      }

      .info-grade {
        position: absolute;
        right: 0;
        top: 50%;
        .grade {
          position: initial;
          display: inline-block;
          width: 18px;
          margin-left: 5px;
          &.square {
            border-radius: 0;
            background: #a2a1a1;
            color: #fff;
            min-width: 18px;
            width: auto;
          }
        }
      }

      .grade {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        background: #f1f1f1;
        width: 18px;
        text-align: center;
        border-radius: 100px;

        &[aria-experience='1'],
        &[aria-experience='2'] {
          background: $error;
          color: #fff;
        }

        &[aria-experience='3'] {
          background: $warning;
        }

        &[aria-experience='4'] {
          background: $info;
          color: #fff;
        }

        &[aria-experience='5'] {
          background: $success;
        }
      }
    }
  }

  .zh-select-user-tooltip-footer {
    padding: rem(10px);
    text-align: right;
    background-color: #f9f9f9;
    border-top: 1px solid #dfe1e6;

    .zh-button-wrapper {
      font-size: rem(13px);
    }
  }
}

.zh-select-user-input {
  .zh-input {
    @include fontSemiBold;
    min-width: rem(100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .zh-icon path {
    fill: #053a31;
  }
}
