@import 'styles/palette';
@import 'styles/helpers';
@import 'styles/typography';

.zh-input-wrapper {
  position: relative;
  border: 1px solid $unactive;
  background-color: $activeBackground;
  border-radius: rem(4px);

  &-active {
    border: 1px solid $primaryBorder;
    background-color: $activeBackground !important;

    .zh-input {
      color: $regularText;
    }
  }

  &-unactive {
    border: 1px solid $unactive;
    background-color: $activeBackground;
  }

  &.disabled {
    border-color: $unactive;
    background-color: $disabledBackground;
    transition: background-color 0.5s linear;

    &:placeholder {
      color: #878787;
    }

    .zh-input {
      color: #878787;
      cursor: not-allowed;
    }
  }

  &.zh-input-style {
    &-transparent {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    &-grey {
      background-color: #f9f9f9;
    }

    &-disabled {
      border: 1px dashed $unactive;
      background-color: #f9f9f9 !important;
    }
  }
}

.zh-input {
  @include fontRegular;
  background: transparent;
  border: none;
  width: 100%;
  display: block;
  height: rem(32.5px);
  font-size: rem(13px);
  padding: rem(0 12.5px);

  &-container {
    position: relative;
    width: 100%;

    &-list {
      position: absolute;
      left: 0;
      top: rem(50px);
      width: 100%;
      list-style: none;
      border: 1px solid $regularBorder;
      min-height: rem(48px);
      max-height: rem(268px);
      background: $activeBackground;
      border-radius: rem(4px);
      z-index: 9999;
      overflow-y: auto;
      padding: 0;
      &__item {
        @include fontRegular;
        padding: rem(16px 7.5px 16px 20px);
        cursor: pointer;
        font-size: rem(12px);
        cursor: pointer;

        &:not(:first-child) {
          border-top: 1px solid $regularBorder;
        }

        &:hover {
          background-color: $secondaryBackgroundHalf;
        }
      }
    }
  }

  &:placeholder {
    color: $unactiveText;
  }

  &:focus {
    outline: none !important;
  }
}
